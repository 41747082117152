import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PageIntro from '../components/PageIntro';
import CapabilitiesSection from '../components/CapabilitiesSection';
import BottomBanner from '../components/BottomBanner';
import Footer from '../components/Footer';

const Capabilities = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className='page capabilities'>
      <PageIntro
        number='01'
        title='Capabilities'
        subtitle='With a broad set of capabilities comes endless '
        mainText={{
          part1: 'Where it all comes together. Combining our beliefs, core values, dreams, and skill sets, this is how we create the future.'
        }}
        image='possibilities'
      />
      <div style={{ height: '2rem', width: '100%' }} />
      <CapabilitiesSection
        photoSide='right'
        icon='Eye'
        title='Creative &amp; Design'
        mainText={{
          part1: "We’re designers and creative thinkers keenly focused on blending thoughtful design and insightful strategy in order to create authentic user experiences that can bridge both digital and physical environments. We dive into our concepts and create stellar multi-sensory experiences across a variety of dynamic media and content. We go beyond surface-level look and feel; we create, curate, and deliver meaningful results in first of their kind ways."
        }}
        listItems={{
          column1: ['Experience Design', 'Industrial Design', 'UI/UX Design', 'Retail Design'],
          column2: ['Package Design', 'Video Production', 'Motion Graphics', '3D Modeling']
        }}
        photoUrl='https://s3.amazonaws.com/mvrk2.0/capes-creative-design-min1.png'
        altText='Creative &amp; Design'
        dotsLocation={isMobile ? 'bottomLeft' : 'bottomRight'}
      />
      <CapabilitiesSection
        photoSide={isMobile ? 'right' : 'left'}
        icon='Tech'
        title='Technology &amp; Development'
        mainText={{
          part1: "Technology is our love language. Our vision in creating a more experiential future is utilizing varying technologies alongside our fellow capabilities to build solutions that evoke curiosity while seamlessly learning more about the world we’ve created.",
          part2: "And just like we dream for our partners, we dream for ourselves. We invest in research and development projects that expand our skillset and help us answer the “what ifs” and find more ways to create the future."
        }}
        listItems={{
          column1: ['Web/Mobile Development', 'Immersive Web Development', 'Product Development', 'Research & Development', 'Retailtainment'],
          column2: ['Virtual Reality (VR)', 'Augmented Reality (AR)', '3D Printing', 'RFID/NFC/BLE', 'Projection Mapping']
        }}
        photoUrl='https://s3.amazonaws.com/mvrk2.0/capes-tech-dev-min.png'
        altText='Technology &amp; Development'
        dotsLocation='bottomLeft'
      />
      {!isMobile && (
        <div style={{ height: '2rem' }} />
      )}
      <CapabilitiesSection
        photoSide='right'
        icon='Rocket'
        title='Strategy'
        mainText={{
          part1: "What’s execution without a plan? Not sure we’re down to find out.",
          part2: "Our strategies are the crux on how we create. We pride ourselves in collaboration with our partners where we roll up our sleeves and get to the why of what matters. From there, we fit each piece of the puzzle carefully into a well-thought out plan that makes the final leg of the race, effortless."
        }}
        listItems={{
          column1: ['Activation Strategy', 'Digital Strategy', 'Event Strategy', 'Product Launches'],
          column2: ['Branded Environments', 'Immersive Retail', 'Theme Park Experiences', 'Product Innovation']
        }}
        photoUrl='https://s3.amazonaws.com/mvrk2.0/capes-strategy-min.png'
        altText='Strategy'
        dotsLocation={isMobile ? 'bottomLeft' : 'topRight'}
      />
      <CapabilitiesSection
        photoSide={isMobile ? 'right' : 'left'}
        icon='Production'
        title='Production'
        mainText={{
          part1: " This is the active ingredient, the action heroes, our version of a Lego master. From the moment our production team begins their efforts, your concepted and strategized experience is catapulted into the real world. They’re the team that put it in front of you, and are the people whose processes, execution, allows for the ultimate pay-off: to be a part of it."
        }}
        listItems={{
          column1: ['Audio/Visual', 'Fabrication', 'Immersive Technologies'],
          column2: ['Interactive Installations', 'Theme Park Interactive', 'Virtual Events']
        }}
        photoUrl='https://s3.amazonaws.com/mvrk2.0/capes-production-min1.png'
        altText='Production'
        dotsLocation={isMobile ? 'bottomLeft' : 'topLeft'}
      />








      <BottomBanner />
      <Footer />
    </div>
  );
}

export default Capabilities;
