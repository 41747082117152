import React from 'react';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MvrkButton from './MvrkButton';
import MvrkHeader from './MvrkHeader';
import ColorBars from './ColorBars';

const Manifesto = ({ history }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <section className='manifesto'>
      <div className='part one'>
        <MvrkHeader
          number='01'
          title='Our Mission'
        />
        <div className='section-content'>
          {!isMobile && (
            <ColorBars
              number={1}
              topPosition={'1700px'}
              rightPosition={''}
              bottomPosition={''}
              leftPosition={0}
            />
          )}
          {!isMobile && (
            <ColorBars
              number={2}
              topPosition={'1375px'}
              rightPosition={0}
              bottomPosition={''}
              leftPosition={''}
            />
          )}
          <div className='subtitle'>We Are Mvrk</div>
          <div className='text'>
            <p>Possibilities are limitless if you’re open to the idea of dreaming bigger than Big. Our mission is to help bring your big ideas to life and then some, so that audiences will always remember when they experienced that moment and who took them there.</p>
            <p>Pushing beyond–way beyond – is what cuts through the noise, makes an impact on audiences and leaves lasting impressions. Being future-minded, multifaceted, and hyper-disciplined at breaking the mold of experiential moments and media is how we inspire our partners to make waves. We create experiences that are innovative and level brands up.</p>
          </div>
          <div className='button-container'>
            <MvrkButton
              buttonText='Get to Know Us'
              action={() => history.push('/about')}
              iconPosition='end'
              iconName='caretRight'
              className='manifesto-button'
              customWidth={200}
            />
          </div>
        </div>
      </div>
      <div className='part two'>
        <figure className='photo'>
          <figcaption className='caption'>
            <div className='caption-line'>
              Pushing boundaries
            </div>
            <div className='caption-line'>
               to change the way we 
            </div>
            <div className='caption-line'>
              experience brands.
            </div>
          </figcaption>
        </figure>
        <div className='dots-background' />
        <div className='arrow' />
      </div>
    </section>
  );
};

export default withRouter(Manifesto);