import React, { PureComponent } from 'react';
import { withContentRect } from 'react-measure';
import { ListItems } from './listItems';

export class List extends PureComponent {
  get cssClass () {
    const { className } = this.props
    return `rcc-List ${className || ''}`
  }
  render () {
    const {
      items,
      measureRef,
      showItemsCount,
      contentRect: { bounds: { width } },
      currentIndex,
      direction,
      transitionDuration,
      translateX,
      scrollItems,
      enable3d,
      nextIndex,
      inTransition,
      effectOf3d,
      handleSelect
    } = this.props;

    return (
      <div className={this.cssClass} ref={measureRef}>
        <ListItems
          itemWidth={width / showItemsCount}
          showItemsCount={showItemsCount}
          currentIndex={currentIndex}
          nextIndex={nextIndex}
          direction={direction}
          transitionDuration={transitionDuration}
          translateX={translateX}
          scrollItems={scrollItems}
          enableDragScroll={false}
          enable3d={enable3d}
          effectOf3d={effectOf3d}
          inTransition={inTransition}
          handleSelect={index => handleSelect(index)}
        >
          {items}
        </ListItems>
      </div>
    )
  }
}

export default withContentRect('bounds')(List)