import React from 'react';


const SwiperSlide = ({ project, index, disabled }) => {
  return (
    <div className='swiper-slide' key={index}>
      <div className='image-container'>
        <img
          className='image'
          src={project.mainPhoto.src}
          alt={project.brand}
        />
      </div>
    </div>
  );
};

export default SwiperSlide;
