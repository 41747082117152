import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { RedPlus, Mvrk, Close, Menu } from '../assets/icons';
import Contact from './Contact';

const careersLink = 'https://www.indeed.com/jobs?q=MVRK';

const useStyles = makeStyles({
  buttonContainer: {
    paddingTop: '1.5rem',
    textAlign: 'right',
    display: 'flex',
    width: '85%',
    justifyContent: 'space-between',
    margin: '0 auto'
  },
  logo: {
    paddingTop: '1.5rem',
  },
  menuButtonContainer: {
    paddingTop: '1.5rem',
  },
  navDrawer: {
    backgroundColor: '#111111',
    width: 375,
  },
  navWrap: {
    background: `url('https://s3.amazonaws.com/mvrk2.0/navTexture.jpeg') no-repeat center center`,
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    opacity: 0.8,
  },
  modalStyle: {
    width: '100vw',
    height: '100vh',
    minHeight: '100vh',
    maxWidth: '100vw',
    margin: 0,
    padding: 0
  },
  modalWrap: {
    backgroundColor: '#111111',
    color: '#FFFFFF',
    height: '100vh',
    width: '100vw',
    maxWidth: '100vw',
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
});

const Nav = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);

  const watchScroll = () => {
    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 20) {
        setHideLogo(true);
      } else {
        setHideLogo(false);
      }
    });
  };

  useEffect(() => {
    watchScroll();
  }, []);

  const handleDisabledClick = e => {
    if (hideLogo) {
      e.preventDefault();
    } 
  };

  return (
    <Toolbar>
      <div className='nav' key='right'>
        <div className={classes.logo}>
          {isMobile ? (
            <div className={`mvrk-logo ${hideLogo ? 'hidden' : ''}`}>
              <NavLink
                to='/'
                className='logo'
                onClick={e => handleDisabledClick(e)}
              >
                <Mvrk />
              </NavLink>
            </div>
          ) : (
            <div className='mvrk-logo'>
              <NavLink to='/' className='logo'>
                <Mvrk />
              </NavLink>
            </div>
          )}
        </div>
        <div className={classes.menuButtonContainer}>
          <IconButton onClick={() => setIsOpen(true)}>
            <Menu />
          </IconButton>
        </div>
        <Drawer
          anchor='right'
          open={isOpen}
          onClose={() => setIsOpen(false)}
          classes={{ paper: classes.navDrawer }}
        >
          <div className={classes.navWrap}>
            <div className={classes.buttonContainer}>
              <div><Mvrk /></div>
              <div>
                <IconButton onClick={() => setIsOpen(false)} >
                  <Close />
                </IconButton>
              </div>
            </div>
            <div className='navMenuList'>
              <List>
                <ListItem>
                  <NavLink
                    exact
                    to='/'
                    className='nav-link'
                    activeClassName='active'
                    onClick={() => setIsOpen(false)}
                  >
                    {location.pathname === '/' ? (
                      <div className='nav-item'>
                        <div className='plus'>
                          <RedPlus />
                        </div>
                        <div className='active-route'>Home</div>
                      </div>
                    ) : (
                      <div className='nav-item'>
                        <div className='inactive-route'>Home</div>
                      </div>
                    )}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    to='/about'
                    className='nav-link'
                    activeClassName='active'
                    onClick={() => setIsOpen(false)}
                  >
                    {location.pathname === '/about' ? (
                      <div className='nav-item'>
                        <div className='plus'>
                          <RedPlus />
                        </div>
                        <div className='active-route'>About</div>
                      </div>
                    ) : (
                      <div className='nav-item'>
                        <div className='inactive-route'>About</div>
                      </div>
                    )}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    to='/capabilities'
                    className='nav-link'
                    activeClassName='active'
                    onClick={() => setIsOpen(false)}
                  >
                    {location.pathname === '/capabilities' ? (
                      <div className='nav-item'>
                        <div className='plus'>
                          <RedPlus />
                        </div>
                        <div className='active-route'>Capabilities</div>
                      </div>
                    ) : (
                      <div className='nav-item'>
                        <div className='inactive-route'>Capabilities</div>
                      </div>
                    )}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    to={{ pathname: 'https://vx360events.com' }}
                    target="_blank"
                    className='nav-link'
                    activeClassName='active'
                    onClick={() => setIsOpen(false)}
                  >
                    {location.pathname === '/virtual-events' ? (
                      <div className='nav-item'>
                        <div className='plus'>
                          <RedPlus />
                        </div>
                        <div className='active-route'>Virtual Events</div>
                      </div>
                    ) : (
                      <div className='nav-item'>
                        <div className='inactive-route'>Virtual Events</div>
                      </div>
                    )}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <div
                    className='fake-nav-link'
                     onClick={() => setIsContactOpen(true)}
                  >
                    <div className='nav-item'>
                      <div className='inactive-route'>Contact</div>
                    </div>
                  </div>
                </ListItem>
                <ListItem>
                  <div
                    className='fake-nav-link'
                     onClick={() => window.open(careersLink, '_blank')}
                  >
                    <div className='nav-item'>
                      <div className='inactive-route'>Careers</div>
                    </div>
                  </div>
                </ListItem>
              </List>
              <div className='nav-social'>
                <div className='social-text'>Follow Us</div>
                <div className='linkedin'>
                  <IconButton onClick={() => window.open('https://www.linkedin.com/company/mvrk/')}>
                    <LinkedInIcon />
                  </IconButton>
                </div>
                <div className='instagram'>
                  <IconButton onClick={() => window.open('https://www.instagram.com/mvrkgroup/')}>
                  <InstagramIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth='xl'
        open={isContactOpen}
        onClose={() => setIsContactOpen(false)}
        aria-labelledby='contact'
        classes={{ paperScrollPaper: classes.modalStyle, scrollPaper: classes.modalStyle, paper: classes.modalStyle, root: classes.modalStyle, container: classes.modalStyle }}
      >
        <div className={classes.modalWrap}>
          <Contact handleClose={() => setIsContactOpen(false)} />
        </div>
      </Dialog>
    </Toolbar>
  );
};

export default Nav;
