import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ContactForm from './ContactForm';
import ContactText from './ContactText';
import Background from '../assets/grid1.png';
import ColorBars from './ColorBars';
import { Close, Mvrk } from '../assets/icons';

const useStyles = makeStyles({
  deskTopContainer: {
    width: '80%',
    margin: '3rem auto'
  },
  mobileContainer: {
    width: '90%',
    margin: '3rem auto'
  },
  contact: {
    position: 'relative'
  },
  bottomRow: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '16px',
    letterSpacing: '-0.03em',
    color: 'rgba(255, 255, 255, 0.31)',
    bottom: '-100px',
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
    width: '100%',
  },
  dotsBackground: {
    width: '100vw',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    background: `url(${Background})`,
    height: 400,
    backgroundSize: 'contain',
    position: 'relative',
    bottom: '0',
    zIndex: '0',
    opacity: '0.25',
    marginTop: '-250px'
  },
  mobileCloseContainer: {
    textAlign: 'right',
    width: '85%',
    margin: '0 auto',
    paddingTop: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeContainer: {
    textAlign: 'right',
    width: '96.25%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    paddingTop: '1.5rem'
  },
  bodyContent: {
    width: '70%',
    margin: '2rem auto 0',
    '@media (max-width: 600px)': {
      width: '95%'
    }
  },
  logo: {
    marginTop: '1.5rem',
    marginLeft: '.25rem',
    '& svg': {
      height: '3rem'
    }
  }
});

const Contact = ({ handleClose }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();

  return (
    <div className={classes.contact}>
      {!isMobile && (
        <ColorBars
          number={7}
          topPosition={'300px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={0}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'100px'}
          rightPosition={0}
          bottomPosition={''}
          leftPosition={''}
        />
      )}
      <div className={isMobile ? classes.mobileCloseContainer : classes.closeContainer}>
        <div className={classes.logo}><Mvrk /></div>
        <IconButton onClick={() => handleClose()} >
          <span className={classes.closeButton}><Close /></span>
        </IconButton>
      </div>
      <div className={classes.bodyContent}>
        <Grid
          container
          spacing={isMobile ? 2 : 5}
          classes={{ container: isMobile ? classes.mobileContainer : classes.desktopContainer }}
          style={{ position: 'relative', zIndex: 2000 }}
        >
          <Grid item xs={12} md={5}>
            <ContactText />
          </Grid>
          <Grid item xs={12} md={7}>
            <ContactForm />
          </Grid>
        </Grid>
        <div className={classes.bottomRow}>
          &copy; MVRK LLC. All Rights Reserved
        </div>
        <div className={classes.dotsBackground} />
      </div>
    </div>
  );
}

export default Contact;
