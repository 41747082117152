import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Plus } from '../assets/icons';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem auto 2rem'
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem auto 2rem',
    justifyContent: 'flex-start'
  },
  plus: {
    height: '13px',
    marginRight: '1.25rem'
  },
  number: {
    fontFamily: 'anonymous-pro',
    fontWeight: 'bold',
    color: '#FC003C',
    fontSize: '21px',
    textTransform: 'uppercase',
    marginRight: '1.25rem'
  },
  title: {
    fontFamily: 'proxima-nova',
    fontWeight: 'bold',
    color: '#38F4F1',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.24em'
  }
});

const MvrkHeader = ({ number, title }) => {
  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
      isMobile ? (
        <div className={classes.mobileHeader}>
          <div className={classes.plus}><Plus /></div>
          <div className={classes.number}>{number}</div>
          <div className={classes.title}>{title}</div>
        </div>
      ) : (
        <div className={classes.header}>
          <div className={classes.plus}><Plus /></div>
          <div className={classes.number}>{number}</div>
          <div className={classes.title}>{title}</div>
        </div>
      )
  );
};

export default MvrkHeader;
