import React, { PureComponent, Children } from 'react';
import throttle from 'lodash/throttle';
import List from './list'
import { DIRECTIONS } from './constants';
import './index.scss';
import PrevArrow from '../../assets/prevArrow.png';
import NextArrow from '../../assets/nextArrow.png';

export class Carousel extends PureComponent {
  constructor() {
    super();
    this.handleNextClick = throttle(this.handleNextClick, 1000);
    this.handlePrevClick = throttle(this.handlePrevClick, 1000);
  }
  get cssClass () {
    const { className } = this.props
    return `rcc-Carousel ${className || ''}`
  }
  state = {
    currentIndex: 0,
    nextIndex: 0,
    dotsIndex: 0,
    translateX: 0,
    inTransition: false,
    scrollItems: 1
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      currentIndex: this.props.startIndex
    })
  }
  handleNextClick = () => {
    const { currentIndex, inTransition, scrollItems } = this.state
    const {
      children,
      transitionDuration,
      showItemsCount,
      shareDirection
    } = this.props;

    const childrenCount = Children.count(children)
    let nextIndex = currentIndex === childrenCount - 1 ? 0 : currentIndex + 1;
    shareDirection('next');

    if (inTransition || (childrenCount < showItemsCount)) {
      return;
    }

    this.setState({
      currentIndex,
      nextIndex,
      scrollItems,
      direction: DIRECTIONS.next,
      transitionDuration,
      translateX: undefined,
      inTransition: true
    });
    setTimeout(() => (
      this.setState({
        currentIndex: nextIndex,
        dotsIndex: nextIndex,
        translateX: 0,
        transitionDuration: 0,
        inTransition: false
      })), transitionDuration * 1000)
  }
  handlePrevClick = () => {
    const { currentIndex, inTransition, scrollItems } = this.state;
    const {
      children,
      transitionDuration,
      showItemsCount,
      shareDirection
    } = this.props;

    const childrenCount = Children.count(children);
    const nextIndex = currentIndex === 0 ? childrenCount - 1 : currentIndex - 1;

    shareDirection('prev');

    if (inTransition || childrenCount < showItemsCount) {
      return;
    }

    this.setState({
      currentIndex,
      nextIndex,
      scrollItems,
      direction: DIRECTIONS.prev,
      transitionDuration,
      translateX: undefined,
      inTransition: true
    });
    setTimeout(() => (
      this.setState({
        currentIndex: nextIndex,
        dotsIndex: nextIndex,
        translateX: 0,
        transitionDuration: 0,
        inTransition: false
      })), transitionDuration * 1000)
  }
  render () {
    const {
      children,
      showItemsCount,
      enable3d,
      effectOf3d,
      listWrapperClassName,
      handleSelect
    } = this.props;

    const {
      currentIndex,
      direction,
      transitionDuration,
      translateX,
      scrollItems,
      nextIndex,
      inTransition
    } = this.state;

    return (
      <div className={this.cssClass}>
        <div>
          <img
            src={PrevArrow}
            className='slider-arrow-prev'
            onClick={this.handlePrevClick}
            alt='navigation-arrow'
          />
          <List
            className={listWrapperClassName}
            items={children}
            currentIndex={currentIndex}
            nextIndex={nextIndex}
            showItemsCount={showItemsCount}
            direction={direction}
            transitionDuration={transitionDuration}
            translateX={translateX}
            scrollItems={scrollItems}
            enableDragScroll={false}
            enable3d={enable3d}
            effectOf3d={effectOf3d}
            inTransition={inTransition}
            handleSelect={index => handleSelect(index)}
          />
          <img
            src={NextArrow}
            className='slider-arrow-next'
            onClick={this.handleNextClick}
            alt='navigation-arrow'
          />
        </div>
      </div>
    )
  }
}

Carousel.defaultProps = {
  transitionDuration: 0.5,
  showItemsCount: 3,
  enableDragScroll: true,
  enable3d: false,
  effectOf3d: { name: 'scale' }
}
