import React, { useState, useRef, useEffect } from 'react';
// import MvrkButton from '../components/MvrkButton';
// import { Player } from 'video-react';
import Globe from '../assets/globe.png';
import Definition from '../assets/definition.png';

const Splash = () => {
  // hero video
  const [isPlaying] = useState(false);
  let videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (isPlaying) {
      video.play();
    }
  }, [isPlaying]);
  
  // background video
  const [playCount, setPlayCount] = useState(0);

  const CreateTheFuture = () => (
    <div className={`create-the-future ${isPlaying ? 'playing' : ''}`}>Create the Future</div>
  );

  useEffect(() => {
    let timer = setTimeout(() => {
      document.getElementById('bgVid').play();
    }, 5000);

    return () => {
      clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (playCount === 1) {
      const bgvid = document.getElementById('bgVid');
      bgvid.src = 'https://s3.amazonaws.com/mvrk2.0/newHero.mp4';
      bgvid.autoplay = true;
      bgvid.loop = true;
    } else {
      return;
    }
  }, [playCount]);

  return (
    <div className='splash'>
      <div className='overlay' />
      <div className='background-video-container'>
        <video
          className='bg-vid'
          id='bgVid'
          autoPlay={false}
          playsInline={true}
          disablePictureInPicture={true}
          muted={true}
          onEnded={() => setPlayCount(playCount + 1)}
        >
          <source src='https://s3.amazonaws.com/mvrk2.0/newHeroA.mp4' type='video/mp4' />
        </video>
      </div>

{/*
      <div className='background-video-container'>
        {!firstVidFinished ? (
          <video
            className='bg-vid'
            id='bgVid'
            autoPlay={false}
            muted={true}
            onEnded={() => setFirstVidFinished(true)}
          >
            <source src='https://s3.amazonaws.com/mvrk2.0/hero1.mp4' type='video/mp4' />
          </video>
        ) : (
          <video
            className='bg-vid'
            id='bgVid2'
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src='https://s3.amazonaws.com/mvrk2.0/hero2.mp4' type='video/mp4' />
          </video>
        )}
      </div>
*/}
{/*
      {isPlaying ? (
        <div className={`video-container ${isPlaying ? 'playing' : ''}`}>
          <Player ref={ref => videoRef.current = ref}>
            <source src='http://media.w3.org/2010/05/sintel/trailer.mp4' />
          </Player>
        </div>
      ) : null}
*/}
      <CreateTheFuture />
{/*}
      <MvrkButton
        buttonText='Watch Reel'
        action={() => setIsPlaying(true)}
        iconPosition='start'
        iconName='play'
        className={`watch-reel ${isPlaying ? 'playing' : ''}`}
      />
*/}
      <div className='splash-footer'>
        <div className='left'>
          <img src={Definition} alt='definition' />
        </div>
        <div className='right'>
          <img src={Globe} alt='globe-logo' />
        </div>
      </div>
    </div>
  );
};

export default Splash;