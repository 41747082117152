import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import history from './routes/history';
import Routes from './routes';
import { CheckCookie } from './utils/CheckCookie';

ReactDOM.render(
  <BrowserRouter>
    <Router history={history}>
      <CheckCookie />
      <Switch>
        <Routes />
      </Switch>
    </Router>
  </BrowserRouter>, 
document.getElementById('root'));

serviceWorker.register();
