import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ColorBars from './ColorBars';

const AboutContent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <section className='about-content'>
      {!isMobile && (
        <ColorBars
          number={5}
          topPosition={'700px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'17.6%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={6}
          topPosition={'1075px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'81%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={2}
          topPosition={'1500px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={3}
          topPosition={'2510px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'81.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'2725px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={3}
          topPosition={'3100px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={0}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'4925px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}

      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'1900px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={0}
        />
      )}
      <div className='about-section-one'>
        <div className='about-subsection one'>
          <div className='schwans' />
        </div>
        <div className='about-subsection two'>
          <div className='agency-details'>
            <p>Through our expertise in creative & design, strategy, production & fabrication, software development & engineering, we’re a group of mavericks creating the future.</p>
            <p>A full-service experiential studio, we’re intentionally and adamantly focused on developing future-forward experiences through insightful creativity, innovative technology and a bit of grit.</p>
          </div>
          <div className='who-we-are'>
            <div className='left'>
              And if there’s anything we’re certain of, it’s who we are and who we’re not
            </div>
            <div className='right'>
              <span className='trait trad'>Traditional</span>
              <span className='trait classic'>Classic</span>
              <span className='trait safe'>Safe</span>
              <span className='trait box'>Predictable</span>
            </div>
          </div>
        </div>
      </div>
      <div className='about-section-two'>
        <figure className='photo'>
          <figcaption className='caption'>
            <span className='quote'>“</span>
            <div className='caption-line'>
              The creative stamp on
            </div>
            <div className='caption-line'>
              their work is truly unique
            </div>
            <div className='caption-line'>
              in the experiential world
            </div>
            <div className='caption-line'>
              and we love working
            </div>
            <div className='caption-line'>
              with them.”
            </div>
            <div className='quote-attr'>
              <div className='name'>Kevin Schwoer</div>
              <div className='company'>Verizon Media</div>
              <div className='title'>Senior Events Manager</div>
            </div>
          </figcaption>
        </figure>
        <div className='mobile-caption'>
          <div className='mobile-caption-line'>
            "The creative stamp on
          </div>
          <div className='mobile-caption-line'>
            their work is truly unique
          </div>
          <div className='mobile-caption-line'>
            in the experiential world
          </div>
          <div className='mobile-caption-line'>
            and we love working
          </div>
          <div className='mobile-caption-line'>
            with them."
          </div>
          <div className='mobile-quote-attr'>
            <div className='name'>Kevin Schwoer</div>
            <div className='company'>Verizon Media</div>
            <div className='title'>Senior Events Manager</div>
          </div>
        </div>
        <div className='dots-background' />
      </div>
    </section>
  );
};

export default AboutContent;
