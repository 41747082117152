import React from 'react';
import LinkedInIcon from '../assets/linkedin.svg';
import InstagramIcon from '../assets/instagram.svg';
import FacebookIcon from '../assets/facebook.svg';
import VimeoIcon from '../assets/vimeo.svg';
import TwitterIcon from '../assets/twitter.svg';
import MediumIcon from '../assets/medium.svg';
import IconButton from '@material-ui/core/IconButton';
import { Mvrk } from '../assets/icons';

const Footer = () => (
  <div className='footer'>
    <div className='top-row'>
      <div className='section logo'>
        <Mvrk />
      </div>
      <div className='section'>
        <div className='footer-section-title'>Orlando HQ</div>
        <p className='footer-section-details'>100 East Pine Street</p>
        <p className='footer-section-details'>Suite 202</p>
        <p className='footer-section-details'>Orlando, FL 32801</p>
      </div>
      <div className='section'>
        <div className='footer-section-title'>New York</div>
        <p className='footer-section-details'>147 Prince Street</p>
        <p className='footer-section-details'>Brooklyn, NY 11201</p>
      </div>
      <div className='section'>
        <div className='footer-section-title mail'>For general inquiries</div>
        <p className='footer-section-details link'>
          <a href='mailto:hello@mvrk.co'>hello@mvrk.co</a>
        </p>
        {/* <p className='footer-section-details link'>
          <a href='tel:4077769107' target='_blank' rel='noopener noreferrer'>(407) 776-9107</a>
        </p> */}
      </div>
      <div className='section social'>
        <div className='facebook' title='Facebook'>
          <IconButton onClick={() => window.open('https://www.facebook.com/mvrkgroup/')}>
            <img src={FacebookIcon} height='20px' width='20px' alt='Facebook link' />
          </IconButton>
        </div>
        <div className='instagram' title='Instagram'>
          <IconButton onClick={() => window.open('https://www.instagram.com/mvrkgroup/')}>
            <img src={InstagramIcon} height='22px' width='22px' alt='Instagram link' />
          </IconButton>
        </div>
        <div className='twitter' title='Twitter'>
          <IconButton onClick={() => window.open('https://twitter.com/MVRKGROUP/')}>
            <img src={TwitterIcon} height='20px' width='20px' alt='Twitter link' />
          </IconButton>
        </div>
        <div className='medium' title='Medium'>
          <IconButton onClick={() => window.open('https://medium.com/@mvrk')}>
            <img src={MediumIcon} height='20px' width='20px' alt='Medium link' />
          </IconButton>
        </div>
        <div className='linkedin' title='LinkedIn'>
          <IconButton onClick={() => window.open('https://www.linkedin.com/company/mvrk/')}>
            <img src={LinkedInIcon} height='22px' width='22px' alt='LinkedIn link' />
          </IconButton>
        </div>
        <div className='vimeo' title='Vimeo'>
          <IconButton onClick={() => window.open('https://vimeo.com/mvrk')}>
            <img src={VimeoIcon} height='20px' width='20px' alt='Vimeo link' />
          </IconButton>
        </div>
      </div>
    </div>
    <div className='bottom-row'>
      &copy; MVRK LLC. All Rights Reserved. <a href="/documents/privacy-policy.pdf" target="_blank" style={{ color: '#38F4F1' }}>Privacy Policy</a>.
    </div>
  </div>
);

export default Footer;