import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Plus } from '../assets/icons';

const FullBlurb = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div className='full-blurb'>
      <div className='plus-wrapper top'><Plus /></div>
      <div className='content'>
        <div className='wrapper'>
          <div className={isMobile ? 'noGlitch' : 'glitch'}>We’re a future-forward experiential studio intensely focused on the next generation of interaction</div>
        </div>
      </div>
      <div className='plus-wrapper bottom'><Plus /></div>
    </div>
  );
};

export default FullBlurb;
