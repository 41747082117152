import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Plus } from '../assets/icons';
import ColorBars from './ColorBars';

const useStyles = makeStyles({
  benefits: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1280px',
    margin: '0 auto'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem auto 2rem'
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem 0 2rem 0',
    justifyContent: 'flex-start'
  },
  plus: {
    height: '13px',
    marginRight: '1.25rem'
  },
  number: {
    fontFamily: 'anonymous-pro',
    fontWeight: 'bold',
    color: '#FC003C',
    fontSize: '21px',
    textTransform: 'uppercase',
    marginRight: '1.25rem'
  },
  title: {
    fontFamily: 'proxima-nova',
    fontWeight: 'bold',
    color: '#38F4F1',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.24em'
  },
  subtitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#FFFFFF',
    width: '55%',
    margin: '0 auto 2rem'
  },
  mobileSubtitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#FFFFFF',
    width: '95%',
    margin: '2rem auto 2rem '
  },
  headerWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4rem'
  },
  mobileHeaderWrap: {
    display: 'flex',
    margin: '4rem 0 0 1.5rem'
  },
  sectionText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '34px',
    textSlign: 'center',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '50%',
    margin: '0 auto',
    textAlign: 'center'
  },
  mobileSectionText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '34px',
    textSlign: 'center',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '90%',
    margin: '0 auto',
    textAlign: 'left'
  },
  gridContainer: {
    width: '75%',
    margin: '2rem auto'
  },
  mobileGridContainer: {
    width: '95%',
    margin: '2rem auto'
  },
  gridRows: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  row: {},
  rowTitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '39px',
    letterSpacing: '-0.01em',
    color: '#38F4F1',
    marginBottom: '.75rem'
  },
  rowText: {
    margin: '0 0 2rem 0',
    width: '90%',
    fontFamily: 'nimbus-sans',
    fontStyle: 'light',
    fontSize: '20px',
    textAlign: 'left',
    letterSpacing: '-2%',
    color: '#FFFFFF'
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  computer: {
    width: '500px',
    height: '500px',
    background: `url('https://s3.amazonaws.com/mvrk2.0/macdesk-min1.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  mobileImageWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  mobileComputer: {
    width: '300px',
    height: '300px',
    background: `url('https://s3.amazonaws.com/mvrk2.0/macdesk-min1.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
});

const VxBenefits = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className={classes.benefits}>
      {!isMobile && (
        <ColorBars
          number={8}
          topPosition={'2375px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'85%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'2300px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      <div className={isMobile ? classes.mobileHeaderWrap : classes.headerWrap}>
        <div className={isMobile ? classes.mobileHeader : classes.header}>
          <div className={classes.plus}><Plus /></div>
          <div className={classes.number}>02</div>
          <div className={classes.title}>Benefits</div>
        </div>
      </div>
      <div className={isMobile ? classes.mobileSubtitle : classes.subtitle}>
        Vx360 Virtual Event Platform Benefits
      </div>
      <div className={isMobile ? classes.mobileSectionText : classes.sectionText}>
        The platform offers limitless possibilities and revolutionizes virtual events, conferences, activations, and exhibits. Delivering meaningful messaging in a first of its kind way, Vx360 goes beyond in-person experiences and creates a new standard in virtual events.
      </div>
      <Grid
        container
        spacing={3}
        classes={{ root: isMobile ? classes.mobileGridContainer : classes.gridContainer }}
        alignContent='space-around'
      >
        <Grid item xs={12} md={5} classes={{ root: classes.gridRows }}>
          <div className={classes.row}>
            <div className={classes.rowTitle}>Immersive</div>
            <div className={classes.rowText}>
              Bring a state-of-the-art event space to life with immersive 360 degree exploration.
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>Customizable</div>
            <div className={classes.rowText}>
              With Vx360 you can completely customize your event with a life-like design.
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>Interactive</div>
            <div className={classes.rowText}>
              Users can be delighted with interactive moments throughout the event space.
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7} classes={{ root: isMobile ? classes.mobileImageWrapper : classes.imageWrapper}}>
          <div className={isMobile ? classes.mobileComputer : classes.computer} />
        </Grid>
      </Grid>
    </div>
  );
};

export default VxBenefits;
