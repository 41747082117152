import React from 'react';
import Splash from '../components/Splash';
import FullBlurb from '../components/FullBlurb';
import Manifesto from '../components/Manifesto';
import Clients from '../components/Clients';
import Experience from '../components/Experience';
import Footer from '../components/Footer';
import BottomBanner from '../components/BottomBanner';

const Home = ({ history }) => {
  return (
    <div className='home'>
      <Splash />
      <FullBlurb />
      <Manifesto history={history}  />
      <Clients />
      <Experience />
      <BottomBanner />
      <Footer />
    </div>
  );
}

export default Home;
