import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { Eye, Tech, Rocket, Production } from '../assets/icons';
import Background from '../assets/grid1.png';
import ColorBars from './ColorBars';

const useStyles = makeStyles({
  container: {
    width: '75%',
    margin: '3rem auto',
    '@media (max-width: 600px)': {
      flexDirection: 'column-reverse',
      width: '95%'
    }
  },
  capesPhotoContainer: {
    position: 'relative',
    height: 600,
    '@media (max-width: 600px)': {
      height: 300,
      textAlign: 'center',
      width: '95%',
      margin: '0 auto'
    }
  },
  capesPhoto: {
    width: '100%',
    height: 500,
    '@media (max-width: 600px)': {
      height: 290,
      width: 'auto'
    }
  },
  capesDotsBottomLeft: {
    background: `url(${Background})`,
    height: 400,
    width: 400,
    backgroundSize: 'contain',
    position: 'relative',
    zIndex: '-1',
    backgroundRepeat: 'no-repeat',
    right: '10%',
    marginTop: '-330px',
    '@media (max-width: 600px)': {
      marginTop: '-215px',
      height: 250,
      width: 250,
      right: '5%'
    }
  },
  capesDotsBottomRight: {
    background: `url(${Background})`,
    height: 400,
    width: 400,
    backgroundSize: 'contain',
    position: 'relative',
    zIndex: '-1',
    backgroundRepeat: 'no-repeat',
    left: '36%',
    marginTop: '-330px'
  },
  capesDotsTopLeft: {
    background: `url(${Background})`,
    height: 400,
    width: 400,
    backgroundSize: 'contain',
    position: 'relative',
    zIndex: '-1',
    backgroundRepeat: 'no-repeat',
    right: '10%',
    marginTop: '-550px'
  },
  capesDotsTopRight: {
    background: `url(${Background})`,
    height: 400,
    width: 400,
    backgroundSize: 'contain',
    position: 'relative',
    zIndex: '-1',
    backgroundRepeat: 'no-repeat',
    left: '36%',
    top: '-550px'
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    margin: '1rem auto 2rem',
    '@media (max-width: 600px)': {
      margin: '0 auto 1rem'
    }
  },
  icon: {
    marginRight: '1rem'
  },
  titleText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '35px',
    lineHeight: '46px',
    letterSpacing: '-0.01em'
  },
  capesBodyText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '22px',
    lineHeight: '37px',
    letterSpacing: '-0.02em',
    width: '90%',
    margin: '0 auto'
  },
  listContainer: {
    display: 'flex',
    fontFamily: 'anonymous-pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    letterSpacing: '-0.02em',
    borderTop: '1px solid #686868',
    paddingTop: '2rem',
    width: '90%',
    margin: '2rem auto 0',
    '@media (max-width: 600px)': {
      margin: '2rem auto 2rem'
    }
  },
  listLeft: {},
  listRight: {
    marginLeft: '2rem'
  },
  item: {
    marginBottom: '.25rem'
  }
});

const CapabilitiesSection = ({
  photoSide,
  icon,
  title,
  mainText,
  listItems,
  photoUrl,
  altText,
  dotsLocation
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles();

  const renderIcon = () => {
    switch (icon) {
      case 'Eye':
        return <Eye />;
      case 'Tech':
        return <Tech />;
      case 'Rocket':
        return <Rocket />;
      case 'Production':
        return <Production />;
      default:
        return;
    }
  };

  return (
    <Grid
      container
      spacing={isMobile ? 0 : 5}
      classes={{ container: classes.container }}
    >
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'250px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={7}
          topPosition={'300px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={0}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={6}
          topPosition={'700px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'85.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={5}
          topPosition={'1200px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'14%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'1875px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={0}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={3}
          topPosition={'2120px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'85.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'2625px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={3}
          topPosition={'2850px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={0}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={8}
          topPosition={'3100px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'46%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'3300px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      <Grid item xs={12} md={6}>
        {photoSide === 'left' ? (
          <div className={classes.capesPhotoContainer}>
            <img
              src={photoUrl}
              className={classes.capesPhoto}
              alt={altText}
            />
            {dotsLocation === 'bottomLeft' && (
              <div className={classes.capesDotsBottomLeft} />
            )}
            {dotsLocation === 'topLeft' && (
              <div className={classes.capesDotsTopLeft} />
            )}
          </div>
        ) : (
          <div className={classes.capesText}>
            <div className={classes.titleRow}>
              <div className={classes.icon}>{renderIcon()}</div>
              <div className={classes.titleText}>{title}</div>
            </div>
            <div className={classes.capesBodyText}>
              <p>{mainText.part1}</p>
              {mainText.part2 && (
                <p>{mainText.part2}</p>
              )}
            </div>
            {listItems && (
              <div className={classes.listContainer}>
                <div className={classes.listLeft}>
                  {listItems.column1.map((item, index) => (
                    <div key={index} className={classes.item}>{item}</div>
                  ))}
                </div>
                {listItems.column2.length && (
                  <div className={classes.listRight}>
                    {listItems.column2.map((item, index) => (
                      <div key={index} className={classes.item}>{item}</div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {photoSide === 'right' ? (
          <div className={classes.capesPhotoContainer}>
            <img
              src={photoUrl}
              className={classes.capesPhoto}
              alt={altText}
            />
            {dotsLocation === 'bottomRight' && (
              <div className={classes.capesDotsBottomRight} />
            )}
            {dotsLocation === 'topRight' && (
              <div className={classes.capesDotsTopRight} />
            )}
            {dotsLocation === 'bottomLeft' && (
              <div className={classes.capesDotsBottomLeft} />
            )}
          </div>
        ) : (
          <div className={classes.capesText}>
            <div className={classes.titleRow}>
              <div className={classes.icon}>{renderIcon()}</div>
              <div className={classes.titleText}>{title}</div>
            </div>
            <div className={classes.capesBodyText}>
              <p>{mainText.part1}</p>
              {mainText.part2 && (
                <p>{mainText.part2}</p>
              )}
            </div>
            <div className={classes.listContainer}>
              <div className={classes.listLeft}>
                {listItems.column1.map((item, index) => (
                  <div key={index} className={classes.item}>{item}</div>
                ))}
              </div>
              {listItems.column2.length && (
                <div className={classes.listRight}>
                  {listItems.column2.map((item, index) => (
                    <div key={index} className={classes.item}>{item}</div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default CapabilitiesSection;
