import React from 'react';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MvrkHeader from './MvrkHeader';
import NeverSettle from '../assets/text/neverSettle.png';
import WorkHard from '../assets/text/workHard.png';
import Relentless from '../assets/text/relentless.png';

const AboutWho = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <section className='about-who'>
      <div className='header-wrap'>
        <MvrkHeader
          number='02'
          title='Who We Are'
        />
      </div>
      <div className='who-subtitle'>
        Meet the Team
      </div>
      <div className='who-sub-heading'>
        Our team of multifaceted doers, dreamers, makers, engineers, and most importantly, solution-based individuals seek to stand out together and are constantly curious of the people, places, and things that surround us.
      </div>
      <div className='grid-wrapper'>
        <div className='dots-background one' />
        <div className='dots-background two' />
        <div className='dots-background three' />
        <div className='text-image work-hard'>
          <img src={WorkHard} alt='Work Hard'/>
        </div>
        <div className='text-image relentless'>
          <img src={Relentless} alt='Relentless' />
        </div>
        <div className='text-image never-settle'>
          <img src={NeverSettle} alt='Never Settle' />
        </div>
        <Grid container spacing={isMobile ? 1 : 3} className='image-container'>
          <Grid item xs={6} md={6}>
            <Grid container spacing={isMobile ? 1 : 3}>
              <Grid item xs={12} md={12}>
                <img
                  src='https://s3.amazonaws.com/mvrk2.0/glow-min.jpeg'
                  className='about-who-image'
                  alt='Netflix Glow Team'
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <img
                  src='https://s3.amazonaws.com/mvrk2.0/cards-min.jpeg'
                  className='about-who-image'
                  alt='Development'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <img
              src='https://s3.amazonaws.com/mvrk2.0/shoes-min.jpeg'
              className='about-who-image tall'
              alt='Shoe Wall'
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <img
              src='https://s3.amazonaws.com/mvrk2.0/addys-crop-min.png'
              className='about-who-image'
              alt='Orlando Addy Awards'
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <img
              src='https://s3.amazonaws.com/mvrk2.0/miami-min.jpeg'
              className='about-who-image spotify'
              alt='MVRK Team at Spotify Viva Latino Live'
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <img
              src='https://s3.amazonaws.com/mvrk2.0/oreo-box-min.png'
              className='about-who-image oreo'
              alt='Oreo Music Box'
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container spacing={isMobile ? 1 : 3}>
              <Grid item xs={12} md={12}>
                <img
                  src='https://s3.amazonaws.com/mvrk2.0/bruno-crop-min.jpeg'
                  className='about-who-image'
                  alt='On Site'
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <img
                  src='https://s3.amazonaws.com/mvrk2.0/assembly.png'
                  className='about-who-image'
                  alt='Oreo Music Box'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={8}>
            <img
              src='https://s3.amazonaws.com/mvrk2.0/xmas-min.jpeg'
              className='about-who-image tall'
              alt='MVRK Christmas Party'
            />
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AboutWho;