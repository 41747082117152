import React, { Component } from 'react';
import { DIRECTIONS, EFFECT_3D_NAMES } from '../../constants';

export class ListItem extends Component {
  handleClick = (event) => {
    const { handleSelect, index } = this.props;
    if (index === 2) {
        handleSelect();
    }
  }
  get translateCoefficient () {
    const { direction, index, showItemsCount, inTransition } = this.props
    const multiplier = 0.2;
    const z = (-1) * Math.abs(0 - index + (showItemsCount + 1) / 2) * multiplier
    if (!inTransition) return z
    const difference = direction === DIRECTIONS.prev ? -1 : 1

    return (-1) * Math.abs(0 - index + difference + (showItemsCount + 1) / 2) * multiplier
  }
  get style3d () {
    const { effectOf3d: { name } } = this.props
    switch (name) {
      case EFFECT_3D_NAMES.daw: return this.style3dDaw
      default: return this.style3dScale
    }
  }
  get style3dScale () {
    const { width, addItemsLeft, transitionDuration } = this.props
    const translate = `translate3d(${width * addItemsLeft * (-1)}px, ${0}px, ${this.translateCoefficient}px)`
    const scale = `scale(${1 / (Math.abs(this.translateCoefficient) + 1)})`
    return {
      width: `${width}px`,
      display: 'inline-block',
      transform: `perspective(1000px) ${translate} ${scale}`,
      transitionDuration: `${transitionDuration}s`
    };
  }
  get style3dDaw () {
    const { width, addItemsLeft, transitionDuration, effectOf3d: { dawStep } } = this.props
    const translate = `translate3d(${width * addItemsLeft * (-1)}px, ${dawStep + this.translateCoefficient * dawStep}px, ${this.translateCoefficient}px)`
    return {
      width: `${width}px`,
      display: 'inline-block',
      transform: `perspective(1000px) ${translate}`,
      transitionDuration: `${transitionDuration}s`
    }
  }
  get style () {
    const { width, addItemsLeft, enable3d, transitionDuration } = this.props
    if (enable3d) return this.style3d
    return {
      width: `${width}px`,
      display: 'inline-block',
      transform: `translate(${width * addItemsLeft * (-1)}px)`,
      transitionDuration: `${transitionDuration}s`
    }
  }
  getClassName = index => {
    switch (index) {
      case 1:
        return `rcc-ListItem previous-slide ${index}`;
      case 2:
        return `rcc-ListItem current-slide ${index}`;
      case 3:
        return `rcc-ListItem next-slide ${index}`;
      default:
        return `rcc-ListItem ${index}`;
    }
  }
  render () {
    const { children, index } = this.props;

    return (
      <div
        className={this.getClassName(index)}
        style={this.style}
        draggable={false}
        onClick={this.handleClick}
      >
        {children}
      </div>
    )
  }
}