import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import LinkedInIcon from '../assets/linkedin.svg';
import InstagramIcon from '../assets/instagram.svg';
import FacebookIcon from '../assets/facebook.svg';
import VimeoIcon from '../assets/vimeo.svg';
import TwitterIcon from '../assets/twitter.svg';
import MediumIcon from '../assets/medium.svg';
import InputContainer from './InputContainer';
import MvrkButton from './MvrkButton';

const useStyles = makeStyles({
  body: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: '34px',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    margin: '2rem auto 2rem'
  },
  location: {},
  locationTitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    marginBottom: '1.5rem'
  },
  locationLine: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '25px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF'
  },
  locationTitleRight: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    marginBottom: '1.5rem',
    paddingLeft: '12px'
  },
  locationLineRight: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '25px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    paddingLeft: '12px'
  },
  inquiryWrap: {
    paddingBottom: '0!important',
    marginTop: '2rem'
  },
  inquiries: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF'
  },
  contact: {
    paddingTop: '0!important'
  },
  email: {
    textDecoration: 'none',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
  },
  phone: {
    textDecoration: 'none',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF'
  },
  spacer: {
    height: '.25rem'
  },
  locationContainerDesktop: {},
  buttonContainerCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem'
  },
  buttonContainerLeft: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: '5rem'
  },
  form: {
    marginTop: '4rem'
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '10rem'
  },
  mail: {
    marginTop: '1rem'
  }
});

const ContactForm = ({
  values,
  handleChange,
  handleBlur,
  dirty,
  validateForm,
  setErrors,
  setTouched,
  handleSubmit,
  resetForm,
  setSubmitting,
  touched,
  errors,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  isValid,
  status
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [apiSuccess, setApiSuccess] = useState(undefined);
  const [apiError, setApiError] = useState(undefined);
  const buttonDisabled = !dirty || !isValid;

  useEffect(() => {
    if (status && status.success === true) {
      setApiSuccess(true);
      setApiError(false);
    } else if (status && status.success === false) {
      setApiError(true);
      setApiSuccess(false);
    }
  }, [status]);

  return (
    <div className='contact-form'>
      {isMobile && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            className={classes.body}
          >
            <div>Got a project you want to discuss?</div>
            <div>Send us a message, we’d love to talk.</div>
          </Grid>
        </Grid>
      )}
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputContainer
              type='text'
              label='Name'
              name='name'
              value={values.name}
              errors={errors}
              onBlur={handleBlur}
              touched={touched}
              onChange={e => setFieldValue('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputContainer
              type='text'
              label='Company'
              name='company'
              value={values.company}
              errors={errors}
              onBlur={handleBlur}
              touched={touched}
              onChange={e => setFieldValue('company', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputContainer
              type='email'
              label='Email'
              name='email'
              value={values.email}
              errors={errors}
              onBlur={handleBlur}
              touched={touched}
              onChange={e => setFieldValue('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputContainer
              type='phone'
              label='Phone'
              name='phone'
              value={values.phone}
              errors={errors}
              onBlur={handleBlur}
              touched={touched}
              onChange={val => setFieldValue('phone', val)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputContainer
              type='textarea'
              label='What can we create together?'
              name='content'
              value={values.content}
              errors={errors}
              onBlur={handleBlur}
              touched={touched}
              onChange={e => setFieldValue('content', e.target.value)}
            />
          </Grid>
          {isMobile ? (
            <Grid item xs={12} classes={{ root: classes.buttonContainerCenter }}>
              <MvrkButton
                buttonText='Submit'
                disabled={buttonDisabled}
                type='submit'
                action={() => handleSubmit()}
                iconPosition='end'
                iconName='caretRight'
                className='manifesto-button'
                customWidth={200}
              />
            </Grid>
          ) : (
            <Grid item xs={12} classes={{ root: classes.buttonContainerLeft }}>
              <MvrkButton
                buttonText='Submit'
                disabled={buttonDisabled}
                type='submit'
                action={() => handleSubmit()}
                iconPosition='end'
                iconName='caretRight'
                className='manifesto-button'
                customWidth={200}
              />
            </Grid>
          )}
        </Grid>
      </form>
      <Snackbar
        open={apiSuccess}
        autoHideDuration={6000}
        onClose={() => setApiSuccess(undefined)}
      >
        <Alert
          onClose={() => setApiSuccess(undefined)}
          severity='success'
        >
          Your message has been sent. We will get back to you as soon as we can!
        </Alert>
      </Snackbar>
      <Snackbar
        open={apiError}
        autoHideDuration={6000}
        onClose={() => setApiError(undefined)}
      >
        <Alert
          onClose={() => setApiError(undefined)}
          severity='error'
        >
          There was an issue sending your message. Please try again.
        </Alert>
      </Snackbar>
      {isMobile && (
        <React.Fragment>
          <Grid
            item
            xs={12}
            className={classes.locationContainerDesktop}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
                className={classes.location}
              >
                <div className={classes.locationTitle}>Orlando HQ</div>
                <div className={classes.locationLine}>100 East Pine Street</div>
                <div className={classes.locationLine}>Suite 202</div>
                <div className={classes.locationLine}>Orlando, FL 32801</div>
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.location}
              >
                <div className={classes.locationTitleRight}>New York</div>
                <div className={classes.locationLineRight}>147 Prince Street</div>
                <div className={classes.locationLineRight}>Brooklyn, NY 11201</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} classes={{ root: classes.inquiryWrap }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                className={classes.inquiries}
              >
                <div className={classes.inquiries}>For General Inquiries</div>
                <div className={classes.mail}>
                  <Link
                    href='mailto:hello@mvrk.co'
                    target='_blank;'
                    classes={{ root: classes.email }}
                  >
                    hello@mvrk.co
                  </Link>
                </div>
                {/* <div className={classes.spacer} /> */}
                {/* <div>
                  <Link
                    href='tel:4075047728'
                    classes={{ root: classes.phone }}
                  >
                    (407) 504-7728
                  </Link>
                </div> */}
              </Grid>
              <Grid item xs={6} classes={{ root: classes.social }}>
                <div className={classes.social}>
                  <div className='facebook' title='Facebook'>
                    <IconButton onClick={() => window.open('https://www.facebook.com/mvrkgroup/')}>
                      <img src={FacebookIcon} height='20px' width='20px' alt='Facebook link' />
                    </IconButton>
                  </div>
                  <div className='instagram' title='Instagram'>
                    <IconButton onClick={() => window.open('https://www.instagram.com/mvrkgroup/')}>
                      <img src={InstagramIcon} height='22px' width='22px' alt='Instagram link' />
                    </IconButton>
                  </div>
                  <div className='twitter' title='Twitter'>
                    <IconButton onClick={() => window.open('https://twitter.com/MVRKGROUP/')}>
                      <img src={TwitterIcon} height='20px' width='20px' alt='Twitter link' />
                    </IconButton>
                  </div>
                  <div className='medium' title='Medium'>
                    <IconButton onClick={() => window.open('https://medium.com/@mvrk')}>
                      <img src={MediumIcon} height='20px' width='20px' alt='Medium link' />
                    </IconButton>
                  </div>
                  <div className='linkedin' title='LinkedIn'>
                    <IconButton onClick={() => window.open('https://www.linkedin.com/company/mvrk/')}>
                      <img src={LinkedInIcon} height='22px' width='22px' alt='LinkedIn link' />
                    </IconButton>
                  </div>
                  <div className='vimeo' title='Vimeo'>
                    <IconButton onClick={() => window.open('https://vimeo.com/mvrk')}>
                      <img src={VimeoIcon} height='20px' width='20px' alt='Vimeo link' />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.contact}
          >

          </Grid>
        </React.Fragment>
      )}
    </div>
  );
};

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Name Is Required'),
  company: Yup.string(),
  email: Yup.string()
    .email('Not A Valid Email Address')
    .required('Email Is Required'),
  phone: Yup.string(),
  content: Yup.string().required('Message Is Required')
});

export default (
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({initialValues = {}}) => {
      return {
        name: '',
        company: '',
        email: '',
        phone: '',
        content: ''
      };
    },
    validationSchema: ContactSchema,
    handleSubmit: async (values, { setSubmitting, setErrors, resetForm, setStatus  }) => {
      setSubmitting(true);

      try {
        const url = 'https://r8f0wfoddk.execute-api.us-east-1.amazonaws.com/main/contact';
        await axios.post(url, values, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

        setStatus({ success: true });
        setSubmitting(false);
        resetForm();

      } catch (error) {
        setStatus({ success: false });
        setSubmitting(false);
      }
    }
  })(ContactForm)
);
