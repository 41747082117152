import React from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles({
  textInputContainer: {
    width: '100%'
  },
  textAreaInputContainer: {
    width: '100%'
  },
  label : {
    fontFamily: 'anonymous-pro',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '25px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.01em',
    color: '#DBDBDB',
    marginBottom: '.25rem'
  },
  phoneInput: {
    backgroundColor: 'white',
    color: 'black',
    width: '100%',
    height: '50px',
    border: '1px solid #D7D7D7',
    fontSize: '1rem',
  },
  input: {
    background: '#FFFFFF',
    border: '1px solid #D7D7D7',
    width: '100%',
    height: '50px',
    fontSize: '1rem',
  },
  textArea: {
    background: '#FFFFFF',
    width: '100%',
    height: '150px',
    border: '1px solid #D7D7D7',
    fontSize: '1rem',
  },
  textInputError: {
    background: '#FFFFFF',
    border: '1px solid #FC003C',
    width: '100%',
    height: '50px',
    fontSize: '1rem',
    paddingLeft: '.5rem',
  },
  textAreaInputError: {
    background: '#FFFFFF',
    border: '1px solid #FC003C',
    width: '100%',
    height: '150px',
    fontSize: '1rem',
    paddingLeft: '.5rem',
    paddingTop: '1rem'
  },
  feedback: {
    color: '#FC003C',
    marginTop: '.25rem',
    fontFamily: 'anonymous-pro',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '25px',
  }
});

const InputContainer = ({
  errors,
  touched,
  label,
  type,
  onChange,
  options = [],
  isVerizon = false,
  ...inputProps
}) => {
  const classes = useStyles();
  const hasError = get(errors, inputProps.name) && get(touched, inputProps.name);

  return (
    <React.Fragment>
      {type === 'textarea' && (
        <div className={isVerizon ? 'vz-textarea-container' : classes.textAreaInputContainer}>
          <div className={isVerizon ? 'vz-label' : classes.label}>
            <label htmlFor={inputProps.name}>
              {label}
            </label>
          </div>
          <textarea
            id={inputProps.name}
            onChange={onChange}
            className={isVerizon ? (hasError ? 'vz-textarea error' : 'vz-textarea') : hasError ? classes.textAreaInputError : classes.textArea}
            {...inputProps}
            value={inputProps.value === null ? '' : inputProps.value}
          />
        </div>
      )}
      {type === 'select' && isVerizon && (
        <React.Fragment>
          <label className='vz-label' htmlFor={inputProps.name}>
            {label}
          </label>
          <select
            id={inputProps.name}
            onChange={onChange}
            className='vz-select'
            {...inputProps}
          >
           {options.length && options.map((option, index) => (
             <option key={index} value={option.value}>{option.label}</option>
           ))}
          </select>
        </React.Fragment>
      )}
      {type === 'text' && (
        <div className={isVerizon ? 'vz-text-input-container' : classes.textInputContainer}>
          <div className={isVerizon ? 'vz-label' : classes.label}>
            <label htmlFor={inputProps.name}>
              {label}
            </label>
          </div>
          <input
            id={inputProps.name}
            onChange={onChange}
            className={isVerizon ? (hasError ? 'vz-text-input error' : 'vz-text-input') : hasError ? classes.textInputError : classes.input}
            type={type}
            {...inputProps}
            value={inputProps.value === null ? '' : inputProps.value}
          />
        </div>
      )}
      {type === 'email' && (
        <div className={isVerizon ? 'vz-text-input-container' : classes.textInputContainer}>
          <div className={isVerizon ? 'vz-label' : classes.label}>
            <label htmlFor={inputProps.name}>
              {label}
            </label>
          </div>
          <input
            id={inputProps.name}
            onChange={onChange}
            className={isVerizon ? (hasError ? 'vz-text-input error' : 'vz-text-input') : hasError ? classes.textInputError : classes.input}
            type={type}
            {...inputProps}
            value={inputProps.value === null ? '' : inputProps.value}
          />
        </div>
      )}
      {type === 'phone' && (
        <div className={isVerizon ? 'vz-text-input-container' : classes.textInputContainer}>
          <div className={isVerizon ? 'vz-label' : classes.label}>
            <label htmlFor={inputProps.name}>
              {label}
            </label>
          </div>
          <MaskedInput
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            {...inputProps}
            className={isVerizon ? 'vz-phone' : classes.phoneInput}
            guide={false}
            id='phone'
            onChange={e => onChange(e.target.value)}
            value={inputProps.value === null ? '' : inputProps.value}
          />
        </div>
      )}
      {get(errors, inputProps.name) && get(touched, inputProps.name) && <div className={isVerizon ? 'vz-feedback' : classes.feedback}>{get(errors, inputProps.name)}</div>}
    </React.Fragment>
  );
};

export default InputContainer;
