import React from 'react';
import BottomBanner from '../components/BottomBanner';
import Footer from '../components/Footer';
import VxIntro from '../components/VxIntro';
import VxPlatform from '../components/VxPlatform';
import VxBenefits from '../components/VxBenefits';
import VxFeatures from '../components/VxFeatures';

const Vx360 = () => {
  return (
    <div className='vx360'>
      <VxIntro />
      <VxPlatform />
      <VxBenefits />
      <VxFeatures />
      <BottomBanner />
      <Footer />
    </div>
  );
}

export default Vx360;
