export const projects = [
  {
    id: 0,
    brand: 'Yahoo Finance Premium',
    title: 'Grand Central',
    title2: 'Launch Event',
    blurb: 'The Gang builds a giant vault filled with cash',
    mainPhoto: { src: 'https://s3.amazonaws.com/mvrk2.0/20190723_YahooFinancePremium_4448-min.jpg' },
    link1: {
      text: 'Read more about the event on',
      link: ' EventMarketer.com',
      url: 'https://www.eventmarketer.com/article/stunts-yahoo-finance-promotes-its-subscription-product-with-a-bank-vault/'
    },
    video: 'https://s3.amazonaws.com/mvrk2.0/mvrkxyahoofp.mp4'
  },
  {
    id: 1,
    brand: 'Smirnoff',
    title: 'Our House is',
    title2: 'Your House',
    blurb: 'The Gang builds a house party in the middle of a desert',
    text: 'In the summer of 2016 MVRK was tapped to help create an experience for EDM concert-goers unlike any they had experienced before - a house party in the middle of a music festival. The house was designed to feel familiar, complete with picture frames on the walls and retro TVs throughout. World famous DJs performed intimate sets while branded bars poured Smirnoff drinks nearby.',
    text2: 'With a theme of inclusivity, MVRK infused a ton of interactive elements to compliment the vibes. Hidden cameras captured the action and displayed videos, photos, and GIFs of house party guests on the picture frames while the TVs displayed a mix of live footage, branded content, and DJ set content. A green screen photo opportunity placed guests in their favorite meme-able moments and a turntable spun in the corner displaying animated images when viewed through a guest’s smartphone camera. ',
    mainPhoto: { src: 'https://s3.amazonaws.com/mvrk2.0/smirnoff1-min.jpg' },
    link1: {
      text: 'Check out our feature on ',
      link: 'Billboard.com',
      url: 'https://www.billboard.com/articles/news/dance/7572681/edc-orlando-smirnoff-pulse-djs-love-wins'
    },
    photos: [
      { src: 'https://s3.amazonaws.com/mvrk2.0/smirnoff1-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/smirnoff2-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/smirnoff3-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/smirnoff4-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/smirnoff5-min.jpg' }
    ]
  },
  {
    id: 2,
    brand: 'Schwan\'s',
    title: 'Super Bowl Live',
    title2: 'Truck Tower',
    blurb: 'The Gang makes a retro video game in freezing temperatures',
    text: 'Helping the household name activate on the biggest stage of the year, our team crafted a unique game show-inspired trivia game displayed on a 45ft high LED wall.',
    text2: 'Using our custom-built registration system, fans created a unique bobblehead as their in-game avatar and digital takeaway for a social sharing opportunity. The activation’s popularity turned heads during the 10-day long fan experience and resulted in a feature on',
    mainPhoto: { src: 'https://s3.amazonaws.com/mvrk2.0/schwans1-min.jpg' },
    link1: {
      text: ' ',
      link: 'Eventmarketer.com.',
      url: 'https://www.eventmarketer.com/article/super-bowl-lii-schwans-celebrates-65-years-with-a-40-foot-truck-tower-and-surprise-sampling/'
    },
    photos: [
      { src: 'https://s3.amazonaws.com/mvrk2.0/schwans1-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/schwans2-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/schwans3-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/schwans4-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/schwans5-min.jpg' },
    ]
  },
  {
    id: 3,
    brand: 'Netflix',
    title: 'FYSEE 2018',
    blurb: 'The Gang recreates Netflix shows at a movie studio pop-up',
    text: 'Leading into the 2018 Emmy Award season, MVRK helped bring the best Netlflix Originals to life throughout an immersive, 30,000 square foot experiential exhibit at Raleigh Studios. The event, lasting five weeks, included props, interactive games, digital photo experiences, and cast appearances.',
    text2: 'Highlights of the expeirence included several intereactive installations including a larger-than-life “Glow Yourself” wall where guests could control the characters from the hit show. In the Mindhunter exhibit, guests were transported to a 1970’s era detective office where they would sit in a desk chair and have their face transformed into a unique Rorschach ink blot before their eyes. One of the most popular interactive experience featured a 1960’s era, digitally-converted jukebox, spinning jokes from the vast lineup of A-list comedians on the Netflix roster.',
    mainPhoto: { src: 'https://s3.amazonaws.com/mvrk2.0/netflix1-min.jpg' },
    photos: [
      { src: 'https://s3.amazonaws.com/mvrk2.0/netflix1-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/netflix2-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/netflix3-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/netflix4-min.jpg' },
    ]
  },
  {
    id: 4,
    brand: 'Trolli',
    title: 'Show Us',
    title2: 'Your Package',
    blurb: 'The Gang eats way too much candy with Deadpool',
    text: 'In the heart of Times Square, Trolli found inspiration from the iconic 90s intimates ads and replicated  the fashion designer-inspired photoshoot in partnership with Deadpool 2 for the worldwide release.',
    text2: 'The cheeky experience welcomed tourists and locals to “Show Us Your Package” by posing alongside the colorful candy. Keeping their modeling photo in black and white, our team created a completely customized photo-editing tool that showcased the bright bag as the photos’ focal point. The tool allowed our team to colorize the bag within seconds, giving fans a real-time opportunity to be featured on a digital billboard, minutes later and our project to be featured in',
    mainPhoto: { src: 'https://s3.amazonaws.com/mvrk2.0/trolli6-min.png' },
    link1: {
      text: ' ',
      link: 'AdWeek',
      url: 'https://www.adweek.com/agencies/deadpool-shows-off-his-tiny-new-package-in-this-trolli-crossover-campaign/'
    },
    link2: {
      text: ' and ',
      link: 'EventMarketer.',
      url: 'https://www.eventmarketer.com/article/showusyourpackage-trolli-and-deadpool-photo-shoot/'
    },
    photos: [
      { src: 'https://s3.amazonaws.com/mvrk2.0/trolli1-min.jpeg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/trolli2-min.png' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/trolli3-min.jpeg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/trolli4-min.jpeg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/trolli6-min.png' }
    ]
  },
  {
    id: 5,
    brand: 'Spotify',
    title: 'Viva Latino Live',
    blurb: 'The Gang makes Insta-worthy photos with hundreds of florals',
    mainPhoto: { src: 'https://s3.amazonaws.com/mvrk2.0/spotify2-min.jpg' },
    video: 'https://s3.amazonaws.com/mvrk2.0/mvrkxspotify.mp4',
  },
  {
    id: 6,
    brand: 'NHL',
    title: 'Celebrating 100',
    title2: 'Years of Hockey',
    blurb: 'The Gang brings NHL history on a 35-city, multi-country tour',
    text: 'To commemorate it’s 100th year, MVRK helped the National Hockey League bring to life an immersive experience that toured the US and Canada over the course of the year. The Centennial Area made stops at every NHL club city, and journeyed over 52,000 miles, welcoming more than 775,000 fans along the way.',
    text2: 'Featured in the museum were wildly popular interactive experiences, including a customizable locker room photo opp, interactive screens that allowed users to navigate historic logos, captains, and trophy winners, a life-sized virtual Stanley Cup, timed audio and video elements, and so much more. Read more about the tour on ',
    mainPhoto: { src: 'https://s3.amazonaws.com/mvrk2.0/nhl1-min.jpg' },
    link1: {
      text: ' ',
      link: 'AdWeek.com',
      url: 'https://www.adweek.com/digital/the-nhl-and-diageo-tap-into-real-time-data-to-modify-the-fan-experience-on-the-fly/'
    },
    photos: [
      { src: 'https://s3.amazonaws.com/mvrk2.0/nhl1-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/nhl2-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/nhl3-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/nhl4-min.jpg' },
      { src: 'https://s3.amazonaws.com/mvrk2.0/nhl5-min.jpg' },
    ]
  },
];


export const generateData = () => {
  return projects.map((project, idx) => ({
    idx: idx,
    project: project
  }));
};