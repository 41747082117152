import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MvrkButton from './MvrkButton';
import Dialog from '@material-ui/core/Dialog';
import Contact from './Contact';

const useStyles = makeStyles({
  modalStyle: {
    width: '100vw',
    height: '100vh',
    minHeight: '100vh',
    maxWidth: '100vw',
    margin: 0,
    padding: 0
  },
  modalWrap: {
    backgroundColor: '#111111',
    color: '#FFFFFF',
    height: '100vh',
    width: '100vw',
    maxWidth: '100vw',
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
});

const BottomBanner = ({ history }) => {
  const classes = useStyles();
  const [isContactOpen, setIsContactOpen] = useState(false);

  return (
    <div className='bottom-banner'>
      <div className='background-dots' />
      <div className='cta'>
        <div className='cta-text'>Let's Create The Future</div>
        <div className='cta-button-container'>
          <MvrkButton
            buttonText='Contact Us'
            action={() => setIsContactOpen(true)}
            iconPosition='end'
            iconName='mail'
            className='cta-button'
            customWidth={200}
          />
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth='xl'
        open={isContactOpen}
        onClose={() => setIsContactOpen(false)}
        aria-labelledby='contact'
        classes={{ paperScrollPaper: classes.modalStyle, scrollPaper: classes.modalStyle, paper: classes.modalStyle, root: classes.modalStyle, container: classes.modalStyle }}
      >
        <div className={classes.modalWrap}>
          <Contact handleClose={() => setIsContactOpen(false)} />
        </div>
      </Dialog>
    </div>
  );
};

export default withRouter(BottomBanner);
