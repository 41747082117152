import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Nav from '../components/Nav';
import VxForm from '../pages/VxForm';
import '../styles/index.scss';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'transparent',
    boxShadow: 'none'
  }
}));

const App = ({ children, location }) => {
  const classes = useStyles();

  return (
    <div className='app'>
      {location.pathname.includes('/vx360') ? (
        <VxForm />
      ) : (
        <React.Fragment>
          <AppBar position='sticky' classes={{ root: classes.appBar }}>
            <Nav />
          </AppBar>
          <main>{children}</main>
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(App);