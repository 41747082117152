import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Play, CaretRight, Mail, Eye, Tech, Rocket, Production, Close, Menu, Plus, Mvrk } from '../assets/icons';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent',
    borderRadius: 45,
    height: 49,
    boxSizing: 'border-box',
    padding: '0 2rem',
    '&:hover': {
      borderColor: '#38F4F1',
      backgroundColor: 'transparent'
    },
    '&:hover path': {
      stroke: '#FC003C'
    },
    '&.Mui-disabled': {
      color: '#505050',
      borderColor: '#505050',
    },
    '&.Mui-disabled path': {
      stroke: '#505050'
    }
  },
  label: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '92.1%',
    letterSpacing: '0.06em',
  },
  disabledLabel: {
    color: '#505050',
    textTransform: 'uppercase',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '92.1%',
    letterSpacing: '0.06em',
  },
  buttonIcon: {
    color: theme.palette.secondary.main,
  },
}));

const MvrkButton = ({ buttonText, action, icon, iconPosition, iconName, className, customWidth, buttonType = 'button', disabled = false }) => {
  const classes = useStyles(customWidth);

  const renderIcon = () => {
    switch (iconName) {
      case 'play':
        return <Play />;
      case 'caretRight':
        return <CaretRight />;
      case 'mail':
        return <Mail />;
      case 'eye':
        return <Eye />;
      case 'tech':
        return <Tech />;
      case 'rocket':
        return <Rocket />;
      case 'production':
        return <Production />;
      case 'close':
        return <Close />;
      case 'menu':
        return <Menu />;
      case 'plus':
        return <Plus />;
      case 'mvrk':
        return <Mvrk />;
      default:
      return null;
    }
  };

  return (
    iconPosition === 'start' ? (
      <Button
        variant='outlined'
        disabled={disabled}
        type={buttonType}
        color='primary'
        onClick={() => action()}
        startIcon={renderIcon()}
        className={className}
        classes={{ root: classes.button, label: classes.label }}
        style={customWidth ? { width: customWidth } : { width: 175 }}
      >
        <div className={disabled ? classes.disabledLabel : ''}>{buttonText}</div>
      </Button>
    ) : (
      <Button
        variant='outlined'
        disabled={disabled}
        color='primary'
        onClick={() => action()}
        endIcon={renderIcon()}
        className={className}
        classes={{ root: classes.button, label: classes.label }}
      >
        <div className={disabled ? classes.disabledLabel : ''}>{buttonText}</div>
      </Button>
    )
  );
};


export default MvrkButton;
