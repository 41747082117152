import React, { useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Player, BigPlayButton } from 'video-react';
import { Plus } from '../assets/icons';
import Background from '../assets/grid1.png';
import ColorBars from './ColorBars';

const useStyles = makeStyles({
  platform: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1280px',
    margin: '8rem auto 0'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem auto 2rem'
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem 0 2rem 0',
    justifyContent: 'flex-start'
  },
  plus: {
    height: '13px',
    marginRight: '1.25rem'
  },
  number: {
    fontFamily: 'anonymous-pro',
    fontWeight: 'bold',
    color: '#FC003C',
    fontSize: '21px',
    textTransform: 'uppercase',
    marginRight: '1.25rem'
  },
  title: {
    fontFamily: 'proxima-nova',
    fontWeight: 'bold',
    color: '#38F4F1',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.24em'
  },
  subtitle: {
    fontFamily: 'anonymous-pro',
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    textAlign: 'center',
    letterSpacing: '0.19em',
    color: '#FFFFFF',
    width: '55%',
    margin: '0 auto 2rem'
  },
  mobileSubtitle: {
    fontFamily: 'anonymous-pro',
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    textAlign: 'center',
    letterSpacing: '0.19em',
    color: '#FFFFFF',
    width: '95%',
    margin: '2rem auto 2rem '
  },
  headerWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4rem'
  },
  mobileHeaderWrap: {
    display: 'flex',
    margin: '4rem 0 0 1.5rem'
  },
  sectionText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '34px',
    textSlign: 'center',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '50%',
    margin: '0 auto',
    textAlign: 'center'
  },
  mobileSectionText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '34px',
    textSlign: 'center',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '90%',
    margin: '0 auto',
    textAlign: 'left'
  },
  videoContainer: {
    width: '60%',
    margin: '3rem auto'
  },
  dotsBackground: {
    background: `url(${Background})`,
    height: 400,
    width: 400,
    backgroundSize: 'contain',
    position: 'absolute',
    zIndex: '-1',
    backgroundRepeat: 'no-repeat',
    left: '54%',
    marginTop: '300px'
  },
  mobileVideoContainer: {
    width: '100%',
    margin: '3rem auto'
  },
  mobileDotsBackground: {
    display: 'none'
  },
});

const VxPlatform = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  let videoRef = useRef(null);

  return (
    <div className={classes.platform}>
      {!isMobile && (
        <ColorBars
          number={2}
          topPosition={'1000px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'800px'}
          rightPosition={'99.5%'}
          bottomPosition={''}
          leftPosition={''}
        />
      )}
      <div className={isMobile ? classes.mobileHeaderWrap : classes.headerWrap}>
        <div className={isMobile ? classes.mobileHeader : classes.header}>
          <div className={classes.plus}><Plus /></div>
          <div className={classes.number}>01</div>
          <div className={classes.title}>The Platform</div>
        </div>
      </div>
      <div className={isMobile ? classes.mobileSubtitle : classes.subtitle}>
        THE Vx360 VIRTUAL EVENT PLATFORM GOES BEYOND IN-PERSON EXPERIENCES
      </div>
      <div className={isMobile ? classes.mobileSectionText : classes.sectionText}>
        Vx360 is MVRK’s virtual environment and event platform. Immersive, web-based, device agnostic, and optimized for desktop and mobile use, the platform’s user experience boasts 360 degree exploration and walkability of a state-of-the-art event space that can be completely customized and life-like in design. Further enhancements to the event experience includes a library of core-features that can be custom-tailored to specific needs and initiatives.
      </div>

      <div className={isMobile ? classes.mobileDotsBackground : classes.dotsBackground} />
      <div className={isMobile ? classes.mobileVideoContainer : classes.videoContainer}>
          <Player
            ref={ref => videoRef.current = ref}
            poster='https://s3.amazonaws.com/mvrk2.0/Vx360poster.png'
          >
            <source src='https://s3.amazonaws.com/mvrk2.0/Vx360.mp4' />
            <BigPlayButton position='center' />
          </Player>
      </div>
    </div>
  );
};

export default VxPlatform;
