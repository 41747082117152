import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Definition from '../assets/definition.png';
import ColorBars from './ColorBars';

const AboutIntro = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <section className='about-intro'>
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'325px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={7}
          topPosition={'470px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={0}
          width={'50%'}
        />
      )}
      <div className='about-image1'>
        <img
          src='https://s3.amazonaws.com/mvrk2.0/led-min.jpeg'
          alt='led wall testing'
        />
      </div>
      <div className='subtitle-container'>
        <div className='subtitle'>We Are Mvrk</div>
        <div className='definition'>
          <img src={Definition} alt='definition' />
        </div>
      </div>
      <div className='sub-heading'>
        <div className='wrapper'>
          <div className={isMobile ? 'noGlitch' : 'glitch'}>We’re rule breakers engineering unconventional ways to bring ideas to life and create a lasting impression.</div>
        </div>
      </div>
    </section>
  );
};

export default AboutIntro;
