import React from 'react';
import PageIntro from '../components/PageIntro';
import AboutIntro from '../components/AboutIntro';
import AboutContent from '../components/AboutContent';
import AboutWho from '../components/AboutWho';
import BottomBanner from '../components/BottomBanner';
import Footer from '../components/Footer';

const About = () => {
  return (
    <div className='page about'>
      <PageIntro
        number='01'
        title='About'
        subtitle='We build, create, and make things differently because we think '
        mainText={{
          part1: "Our diverse team of creative strategists, producers, and engineers develop hyper-immersive moments in unconventional ways. Our work and partnerships span across the world’s biggest brands, passions, lifestyles, theme parks, and IP.",
          part2: "Forwardly-thinking, we imagine how we can make a lasting impact that will change the way we’ve known to experience the world, IRL."
        }}
        image='differently'
      />
      <AboutIntro />
      <AboutContent />
      <AboutWho />
      <BottomBanner />
      <Footer />
    </div>
  );
}

export default About;
