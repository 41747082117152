import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import LinkedInIcon from '../assets/linkedin.svg';
import InstagramIcon from '../assets/instagram.svg';
import FacebookIcon from '../assets/facebook.svg';
import VimeoIcon from '../assets/vimeo.svg';
import TwitterIcon from '../assets/twitter.svg';
import MediumIcon from '../assets/medium.svg';
import MvrkHeader from './MvrkHeader';

const useStyles = makeStyles({
  section: {},
  subtitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '52px',
    lineHeight: '29px',
    letterSpacing: '-0.01em',
    color: '#FFFFFF'
  },
  body: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: '34px',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    marginBottom: '2rem'
  },
  location: {},
  locationTitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    marginBottom: '1.5rem'
  },
  locationLine: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '25px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF'
  },
  locationTitleRight: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    marginBottom: '1.5rem',
    paddingLeft: '12px'
  },
  locationLineRight: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '25px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    paddingLeft: '12px'
  },
  inquiryWrap: {
    paddingBottom: '0!important'
  },
  inquiries: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF'
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '10rem'
  },
  linkedIn: {
    color: '#38F4F1',
    '&:hover': {
      color: '#088b89'
    }
  },
  instagram: {
    color: '#38F4F1',
    '&:hover': {
      color: '#088b89'
    }
  },
  email: {
    textDecoration: 'none',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
  },
  phone: {
    textDecoration: 'none',
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF'
  },
  spacer: {
    height: '.25rem'
  },
  locationContainerDesktop: {},
  mail: {
    marginTop: '1rem'
  }
});

const ContactText = () => {
  const classes = useStyles();
  const notMobile = useMediaQuery('(min-width:600px)');

  return (
    <div className={classes.section}>
      <MvrkHeader
        number='01'
        title='Contact'
      />
      <Grid
        container
        spacing={4}
        classes={{ container: classes.outerContainer }}>
        <Grid
          item
          xs={12}
          className={classes.subtitle}
        >
          Let's Talk!
        </Grid>
        {notMobile && (
          <React.Fragment>
            <Grid
              item
              xs={12}
              className={classes.body}
            >
              <div>Got a project you want to discuss?</div>
              <div>Send us a message, we’d love to talk.</div>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.locationContainerDesktop}
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                  className={classes.location}
                >
                  <div className={classes.locationTitle}>Orlando HQ</div>
                  <div className={classes.locationLine}>100 East Pine Street</div>
                  <div className={classes.locationLine}>Suite 202</div>
                  <div className={classes.locationLine}>Orlando, FL 32801</div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={classes.location}
                >
                  <div className={classes.locationTitleRight}>New York</div>
                  <div className={classes.locationLineRight}>147 Prince Street</div>
                  <div className={classes.locationLineRight}>Brooklyn, NY 11201</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} classes={{ root: classes.inquiryWrap }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  className={classes.inquiries}
                >
                  <div className={classes.inquiries}>For General Inquiries</div>
                  <div className={classes.mail}>
                    <Link
                      href='mailto:hello@mvrk.co'
                      classes={{ root: classes.email }}
                    >
                      hello@mvrk.co
                    </Link>
                  </div>
                  {/* <div className={classes.spacer} />
                  <div>
                    <Link
                      href='tel:4077769107'
                      classes={{ root: classes.phone }}
                    >
                      (407) 776-9107
                    </Link>
                  </div> */}

                </Grid>
                <Grid item xs={6}>
                  <div className={classes.social}>
                    <div className='facebook' title='Facebook'>
                      <IconButton onClick={() => window.open('https://www.facebook.com/mvrkgroup/')}>
                        <img src={FacebookIcon} height='20px' width='20px' alt='Facebook link' />
                      </IconButton>
                    </div>
                    <div className='instagram' title='Instagram'>
                      <IconButton onClick={() => window.open('https://www.instagram.com/mvrkgroup/')}>
                        <img src={InstagramIcon} height='22px' width='22px' alt='Instagram link' />
                      </IconButton>
                    </div>
                    <div className='twitter' title='Twitter'>
                      <IconButton onClick={() => window.open('https://twitter.com/MVRKGROUP/')}>
                        <img src={TwitterIcon} height='20px' width='20px' alt='Twitter link' />
                      </IconButton>
                    </div>
                    <div className='medium' title='Medium'>
                      <IconButton onClick={() => window.open('https://medium.com/@mvrk')}>
                        <img src={MediumIcon} height='20px' width='20px' alt='Medium link' />
                      </IconButton>
                    </div>
                    <div className='linkedin' title='LinkedIn'>
                      <IconButton onClick={() => window.open('https://www.linkedin.com/company/mvrk/')}>
                        <img src={LinkedInIcon} height='22px' width='22px' alt='LinkedIn link' />
                      </IconButton>
                    </div>
                    <div className='vimeo' title='Vimeo'>
                      <IconButton onClick={() => window.open('https://vimeo.com/mvrk')}>
                        <img src={VimeoIcon} height='20px' width='20px' alt='Vimeo link' />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

export default ContactText;
