import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../styles/theme';
import App from '../containers/App';
import Home from '../pages/Home';
import About from '../pages/About';
import Capabilities from '../pages/Capabilities';
import Vx360 from '../pages/Vx360';

const Routes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <App>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/capabilities' component={Capabilities} />
          <Route exact path='/virtual-events' component={Vx360} />
{/*
          <Route exact path='/admin'component={Admin} />
*/}
        </Switch>
      </App>
    </ThemeProvider>
  );
};

export default Routes;