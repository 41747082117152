import React from 'react';
import MvrkHeader from './MvrkHeader';

const Clients = () => (
  <section className='clients'>
    <div className='part one'>
      <div className='content'>
        <MvrkHeader
          number='02'
          title='Clients'
        />
        <div className='section-content'>
          <div className='subtitle'>Trusted By</div>
          <div className='text'>
            <p>It never feels like work when you’re partnering with the world’s most innovative brands to make some really amazing experiences.</p>
          </div>
        </div>
      </div>
    </div>
    <div className='part two'>
      <div className='logos'>
        <div className='logo verizon' />
        <div className='logo netflix' />
        <div className='logo nike' />
        <div className='logo prime' />
        <div className='logo oreo' />
        <div className='logo snapchat' />
        <div className='logo spotify' />
        <div className='logo directv' />
        <div className='logo nhl' />
        <div className='logo facebook' />
        <div className='logo delta' />
        <div className='logo smirnoff' />
      </div>
    </div>
  </section>
);

export default Clients;
