import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import InputContainer from '../components/InputContainer';
import Logo from '../assets/logos/vx-form-logo.svg';

const VxForm = ({
  values,
  handleChange,
  handleBlur,
  dirty,
  validateForm,
  setErrors,
  setTouched,
  handleSubmit,
  resetForm,
  setSubmitting,
  touched,
  errors,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  isValid,
  status,
  location
}) => {
  // const client = location.pathname.split('/')[2];

  const [apiSuccess, setApiSuccess] = useState(undefined);
  /* eslint-disable no-unused-vars */
  const [apiError, setApiError] = useState(undefined);
  /* eslint-enable no-unused-vars */
  const buttonDisabled = !dirty || !isValid || isSubmitting;
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    if (status && status.success === true) {
      setApiSuccess(true);
      setApiError(false);
      setShowSuccessToast(true);
    } else if (status && status.success === false) {
      setApiError(true);
      setApiSuccess(false);
      setShowErrorToast(true);
    }
  }, [status]);

  return (
    <div className='vx-form'>
      <div className='header-container'>
        <header className='header'>
          <div className='logo-container'>
            <img src={Logo} className='form-logo' alt='Logo' />
          </div>
          <div className='contact-container'>
            <a className='text-link' href='mailto:ableisnick@mvrk.co'>Questions?  <span className='spacer' />Contact Us</a>
          </div>
        </header>
      </div>
      <main className='form-container'>
        {apiSuccess ? (
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <div className='thanks'>Thank you for your submission.</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className='next-steps'>
                <div className='expect'>What to expect next:</div>
                <ol className='list'>
                  <li>Annie Bleisnick will confirm receipt of request.</li>
                  <li>Once Annie reviews the request from a calendar prospective, you will be introduced to your dedicated MVRK Sr. Account Manager.</li>
                  <li>Next, your dedicated MVRK Sr. Account Manager will reach out with the project detail questionnaire document (questionnaire document includes information surrounding content, program specifics, etc).</li>
                  <li>Your MVRK Sr. Account Manager will work with you to schedule a kick off meeting.</li>
                  <li>During the kick off, the Verizon event lead will properly kick off and brief the MVRK team.</li>
                  <li>Coming out of the kick off, your MVRK Sr. Account Manager will share an initial RACI sheet, workback schedule, schedule weekly calls, and provide you with clear next steps on when you will receive the first round of creative.</li>
                </ol>
              </div>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <div className='intro'>
                  <div className='intro-title'>
                    Virtual Platform project intake form
                  </div>
                  <div className='intro-text'>
                    <p>Thank you for your interest in the Verizon Virtual Platform. Please fill out the following questions and share back directly with Annie Bleisnick, MVRK Account Director (<a className='text-link' href='mailto:ableisnick@mvrk.co'>ableisnick@mvrk.co</a>) so your project can be properly assessed.</p>
                    <p>This document functions as your brief, and any additional information/questions that the team may need, they will reach out to you about directly.</p>
                    <p>Allow up to 24-48 hours for a follow up to your request.</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <form className='form' onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className='form-section-header'>
                        Contact details
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='text'
                        label='Name'
                        name='name'
                        value={values.name}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('name', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='text'
                        label='Email'
                        name='email'
                        value={values.email}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('email', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='phone'
                        label='Phone number'
                        name='phone'
                        value={values.phone}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                         onChange={val => setFieldValue('phone', val)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className='form-section-header'>
                        Background and event information
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='select'
                        label='Which Verizon business unit is your opportunity for?'
                        name='businessUnit'
                        options={[
                          { label: 'Select business unit', value: '' },
                          { label: 'VMG', value: 'VMG' },
                          { label: 'VBG', value: 'VBG' },
                          { label: 'VCG', value: 'VCG' },
                          { label: 'Other', value: 'Other' },
                        ]}
                        value={values.businessUnit}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('businessUnit', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='select'
                        options={[
                          { label: 'Select environment type', value: '' },
                          { label: 'Repurpose an existing environment', value: 'Repurpose' },
                          { label: 'Create a new one', value: 'New' },
                          { label: 'I am not sure, I would like to talk through this', value: 'Unknown' }
                        ]}
                        label='What type of environment will you be using?'
                        name='environment'
                        value={values.environment}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('environment', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='Provide a brief overview and background of the program'
                        name='overview'
                        value={values.overview}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('overview', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='Main objectives-what do you want to achieve?'
                        name='objectives'
                        value={values.objectives}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('objectives', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='Who is your target audience? Please provide some insight'
                        name='targetAudience'
                        value={values.targetAudience}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('targetAudience', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label={`Summary of KPI's or key takeaways and success metrics`}
                        name='kpi'
                        value={values.kpi}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('kpi', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='Are we solving for a business program or creating an opportunity?'
                        name='programOrOpportunity'
                        value={values.programOrOpportunity}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('programOrOpportunity', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='What is the single most important message?'
                        name='message'
                        value={values.message}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('message', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='What type of content and experiences will be featured?'
                        name='content'
                        value={values.content}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('content', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='What are users getting out of the experience?'
                        name='userTakeaways'
                        value={values.userTakeaways}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('userTakeaways', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className='form-section-header'>
                        Platform creative considerations
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='Creative direction-look and feel of the environment'
                        name='creativeDirection'
                        value={values.creativeDirection}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('creativeDirection', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label='What is the tone?'
                        name='tone'
                        value={values.tone}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('tone', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='select'
                        label='If creating a new environment, please confirm the navigation'
                        options={[
                          { label: 'Select navigation type', value: '' },
                          { label: '2D (flat design similar to a website, navigate using button links)', value: '2d' },
                          { label: 'Hybrid (3D environment design with a fixed perspective, navigate using buttonlinked on a fixed path)', value: 'Hybrid' },
                          { label: '3D (360-degree perspective, navigate with 360-degree movement controls)', value: '3d' },
                          { label: 'I  am not sure, lets talk through this', value: 'Undecided' }
                        ]}
                        name='navigation'
                        value={values.navigation}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('navigation', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='textarea'
                        label={`Are there other activations or digital experiences you've seen and loved?`}
                        name='previousExperiences'
                        value={values.previousExperiences}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('previousExperiences', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className='form-section-header'>
                        Logistical questions
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='text'
                        label='Confirmed project budget for digital experience'
                        name='budget'
                        value={values.budget}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('budget', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='text'
                        label='Approximate number of guests'
                        name='guests'
                        value={values.guests}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('guests', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputContainer
                        isVerizon
                        type='text'
                        label='Requested month/dates of program and timing'
                        name='date'
                        value={values.date}
                        errors={errors}
                        onBlur={handleBlur}
                        touched={touched}
                        onChange={e => setFieldValue('date', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className='button-container'>
                        <button
                          disabled={buttonDisabled}
                          type='button'
                          onClick={handleSubmit}
                          className={`form-submit-button ${!isValid || !dirty ? 'disabled' : ''}`}
                        >
                          Submit
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <Snackbar
          open={showSuccessToast}
          autoHideDuration={6000}
          onClose={() => setShowSuccessToast(false)}
        >
          <Alert
            onClose={() => setShowSuccessToast(false)}
            severity='success'
          >
            Your form has been submitted. We will get back to you as soon as we can!
          </Alert>
        </Snackbar>
        <Snackbar
          open={showErrorToast}
          autoHideDuration={6000}
          onClose={() => setShowErrorToast(false)}
        >
          <Alert
            onClose={() => setShowErrorToast(false)}
            severity='error'
          >
            There was an issue saving the form. Please try again.
          </Alert>
        </Snackbar>
      </main>
    </div>
  );
};

const FormSchema = Yup.object().shape({
  client: Yup.string(),
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Not a valid email address')
    .required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  businessUnit: Yup.string().required('Business unit is required'),
  environment: Yup.string().required('Environment type is required'),
  overview: Yup.string().required('Overview and background are required'),
  objectives: Yup.string().required('Objectives are required'),
  targetAudience: Yup.string().required('Target audience is required'),
  kpi: Yup.string().required('KPI summary is required'),
  programOrOpportunity: Yup.string().required('Program or opportunity is required'),
  message: Yup.string().required('Message is required'),
  content: Yup.string().required('Content type is required'),
  userTakeaways: Yup.string().required('User takeaways are required'),
  creativeDirection: Yup.string().required('Creative direction is required'),
  tone: Yup.string().required('Tone is required'),
  navigation: Yup.string().required('Navigation is required'),
  previousExperiences: Yup.string().required('Previous experiences are required'),
  budget: Yup.string().required('Budget is required'),
  guests: Yup.string().required('Number of guests is required'),
  date: Yup.string().required('Date is required'),
});

export default (
  withFormik({
    enableReinitialize: true,
    validationSchema: FormSchema,
    handleSubmit: async (values, { setSubmitting, resetForm, setStatus  }) => {
      setSubmitting(true);
      try {
        const url = 'https://ll9y9jozbh.execute-api.us-east-1.amazonaws.com/staging/vx360-contact';
        await axios.post(url, values, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

        setStatus({ success: true });
        setSubmitting(false);
        resetForm();

      } catch (error) {
        setStatus({ success: false });
        setSubmitting(false);
      }
    }
  })(withRouter(VxForm))
);
