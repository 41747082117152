import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CookieConsent from 'react-cookie-consent';
import { GoogleInit, RouteTracker } from './GoogleAnalytics';

const cookieName = 'mvrkHqCookie';

export const checkCookieValue = (name = cookieName) => {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift();
  }
};

const buttonStyle = {
  backgroundColor: 'transparent',
  border: '1px solid #ffffff',
  color: '#ffffff',
  borderRadius: 45,
  height: 49,
  boxSizing: 'border-box',
  fontSize: '12px',
  textTransform: 'uppercase',
  width: '150px',
  padding: '0px 2rem 0 1rem',
  fontFamily: 'nimbus-sans'
};

const contentStyle = {
  fontSize: '15px',
  fontFamily: 'nimbus-sans',
  textAlign: 'center',
  flex: 'auto',
  flexWrap: 'wrap'
};

export const CheckCookie = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isCookieSet, setCookie] = useState(checkCookieValue());

  return (
    <React.Fragment>
      <CookieConsent
        buttonText='Accept'
        cookieName={cookieName}
        style={{
          background: '#272727',
          width: isMobile ? '101vw' : '50%',
          margin: '0 auto',
          left: '50%',
          transform: 'translateX(-50%)',
          height: isMobile ? '155px' : '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: isMobile ? 'wrap' : 'no-wrap',
          flexFlow: isMobile ? 'row wrap' : ''
        }}
        contentStyle={contentStyle}
        buttonStyle={buttonStyle}
        buttonClasses='acceptButton'
        expires={150}
        onAccept={() => setCookie(true)}
      >
        We use cookies on this site to enhance your experience. By continuing to use our site, you accept our use of cookies and our <a href="/documents/privacy-policy.pdf" target="_blank" >Privacy Policy</a>.
      </CookieConsent>
      {isCookieSet ? GoogleInit() && <RouteTracker /> : null}
    </React.Fragment>
  );
};