import React, { useRef } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { Player, BigPlayButton } from 'video-react';
import { Close, Mvrk, Plus } from '../assets/icons';

const ExperienceModal = ({ project, close }) => {
  let photos;

  if (project.photos) {
    photos = project.photos.map((photo, index) => (
      <div key={index} data-src={photo.src} />
    ));
  }

  let videoRef = useRef(null);

  return (
    <div className='experience-modal'>
      <div className='modal-menu'>
        <div className='logo'>
          <Mvrk />
        </div>
        <div className='close' onClick={close}>
          <Close />
        </div>
      </div>
      <div className='modal-header'>
        <div className='plus'><Plus /></div>
        <div className='number'>03</div>
        <div className='title'>Experience</div>
      </div>
      <div className='modal-subtitle'>
        {`${project.brand} ${project.title} ${project.title2 ? project.title2 : ''}`}
      </div>
      <div className='modal-body'>
        {project.video ? (
          <section className='video'>
            <Player ref={ref => videoRef.current = ref}>
              <source src={project.video} />
              <BigPlayButton position='center' />
            </Player>
            {project.link1 && (
              <div className='video-page-link'>
                {project.link1.text}
                <a href={project.link1.url} target='_blank' rel='noopener noreferrer'>
                  {project.link1.link}
                </a>
              </div>
            )}
          </section>
        ) : (
          <React.Fragment>
            <section className='part one'>
              <div className='sub-section'>
                <div className='gallery'>
                  <AwesomeSlider>
                    {photos}
                  </AwesomeSlider>
                </div>
              </div>
            </section>
            <section className='part two'>
              <div className='sub-section'>
                <p className='modal-text one'>{project.text}</p>
                <p className='modal-text'>
                  {project.text2}
                  {project.link1 && (
                    <span className='gallery-page-link'>
                      {project.link1.text}
                      <a href={project.link1.url} target='_blank' rel='noopener noreferrer'>
                        {project.link1.link}
                      </a>
                    </span>
                  )}
                  {project.link2 && (
                    <span className='gallery-page-link'>
                      {project.link2.text}
                      <a href={project.link2.url} target='_blank' rel='noopener noreferrer'>
                        {project.link2.link}
                      </a>
                    </span>
                  )}
                </p>
              </div>
            </section>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ExperienceModal;
