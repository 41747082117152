import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Plus } from '../assets/icons';
import ColorBars from './ColorBars';

const useStyles = makeStyles({
  features: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1280px',
    margin: '0 auto -120px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem auto 2rem'
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem 0 2rem 0',
    justifyContent: 'flex-start'
  },
  plus: {
    height: '13px',
    marginRight: '1.25rem'
  },
  number: {
    fontFamily: 'anonymous-pro',
    fontWeight: 'bold',
    color: '#FC003C',
    fontSize: '21px',
    textTransform: 'uppercase',
    marginRight: '1.25rem'
  },
  title: {
    fontFamily: 'proxima-nova',
    fontWeight: 'bold',
    color: '#38F4F1',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.24em'
  },
  subtitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#FFFFFF',
    width: '55%',
    margin: '0 auto 2rem'
  },
  mobileSubtitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#FFFFFF',
    width: '95%',
    margin: '2rem auto 2rem '
  },
  headerWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4rem'
  },
  mobileHeaderWrap: {
    display: 'flex',
    margin: '4rem 0 0 1.5rem'
  },
  sectionText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '34px',
    textSlign: 'center',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '50%',
    margin: '0 auto',
    textAlign: 'center'
  },
  mobileSectionText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '34px',
    textSlign: 'center',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '90%',
    margin: '0 auto',
    textAlign: 'left'
  },
  gridContainer: {
    width: '75%',
    margin: '2rem auto'
  },
  mobileGridContainer: {
    width: '95%',
    margin: '2rem auto'
  },
  row: {
    height: '10rem'
  },
  mobileRow: {},
  rowTitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '39px',
    letterSpacing: '-0.01em',
    color: '#38F4F1',
  },
  mobileRowTitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '39px',
    letterSpacing: '-0.01em',
    color: '#38F4F1',
  },
  rowText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    marginBottom: '3rem'
  },
  mobileRowText: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    marginBottom: '3rem'
  }
});

const VxFeatures = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className={classes.features}>
      {!isMobile && (
        <ColorBars
          number={3}
          topPosition={'3250px'}
          rightPosition={'96.5%'}
          bottomPosition={''}
          leftPosition={''}
        />
      )}
      <div className={isMobile ? classes.mobileHeaderWrap : classes.headerWrap}>
        <div className={isMobile ? classes.mobileHeader : classes.header}>
          <div className={classes.plus}><Plus /></div>
          <div className={classes.number}>03</div>
          <div className={classes.title}>Features</div>
        </div>
      </div>
      <div className={isMobile ? classes.mobileSubtitle : classes.subtitle}>
        Features of the Vx360 Virtual Event Platform
      </div>
      <div className={isMobile ? classes.mobileSectionText : classes.sectionText}>
        Through embedded interactive VOD content, live stream capabilities, two-way communication integration, a rich focus on crowd-sourcing and user engagement, expanded analytic tracking, and a variety of other integrated capabilities; Vx360 offers best views for premium content and an architectural framework built for revolutionary brand moments and amplified engagement opportunity.
      </div>
      <Grid
        container
        spacing={3}
        classes={{ root: isMobile ? classes.mobileGridContainer : classes.gridContainer }}
        alignContent='space-around'
      >
        <Grid item xs={12} md={4} classes={{ root: isMobile ? classes.mobileGridColumn : classes.gridColumn }}>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>3D Environment</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Immersive, completely customizable 360 degree exploration
            </div>
          </div>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>Live Chat</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Integrated networking features for enhanced platform communication
            </div>
          </div>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>Polls &amp; Surveys</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Pop-up interactive features allow for further attendee engagement
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} classes={{ root: isMobile ? classes.mobileGridColumn : classes.gridColumn }}>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>Device Agnostic</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Fully optimized for desktop and mobile
            </div>
          </div>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>Video Chat</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Foster genuine community connections with integrated group video chat
            </div>
          </div>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>Demos &amp; Games</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Completely custom interactive demos, animations, and games
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} classes={{ root: isMobile ? classes.mobileGridColumn : classes.gridColumn }}>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>Live Stream</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Powerful, embedded live streaming with interactive polls and polls
            </div>
          </div>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>Enhanced Q&amp;A</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Interact with presenters and attendees through enhanced session Q&A
            </div>
          </div>
          <div className={isMobile ? classes.mobileRow : classes.row}>
            <div className={isMobile ? classes.mobileRowTitle : classes.rowTitle}>On-Demand Content</div>
            <div className={isMobile ? classes.mobileRowText : classes.rowText}>
              Embedded premium video-on-demand content
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default VxFeatures;
