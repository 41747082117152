import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import ColorBars from './ColorBars';
import { Plus } from '../assets/icons';

const useStyles = makeStyles({
  intro: {
    marginTop: '-5rem',
    height: '80vh'
  },
  bgVidContainer: {
    position: 'absolute',
    width: '100%',
    minWidth: '100vw',
    maxWidth: '100vw',
    maxHeight: '100vh',
    margin: '0 auto',
    overflow: 'hidden',
    zIndex: -1,
  },
  mobileBgVidContainer: {
    position: 'absolute',
    width: '100%',
    minWidth: '100vw',
    maxWidth: '100vw',
    maxHeight: '100vh',
    margin: '0 auto',
    overflow: 'hidden',
    zIndex: -1,
  },
  backgroundVid: {
    height: '100vh',
    objectFit: 'fill',
    minWidth: '100vw'
  },
  mobileBackgroundVid: {
    height: '100vh',
    objectFit: 'cover',
    width: '100vw'
  },
  introText: {
    position: 'absolute',
    left: '15%',
    width: '30rem',
    top: '15%'
  },
  mobileIntroText: {
    position: 'absolute',
    left: '5%',
    width: '20rem',
    top: '5%'
  },
  headerWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '4rem'
  },
  mobileHeaderWrap: {
    display: 'flex',
    margin: '4rem 0 0 0'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 2rem 0'
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem 0 2rem 0',
    justifyContent: 'flex-start'
  },
  plus: {
    height: '13px',
    marginRight: '1.25rem'
  },
  title: {
    fontFamily: 'proxima-nova',
    fontWeight: 'bold',
    color: '#38F4F1',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.24em',
    width: '75%'
  },
  introTitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '38px',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '75%',
    marginBottom: '2rem'
  },
  mobileIntroTitle: {
    fontFamily: 'nimbus-sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '28px',
    letterSpacing: '-0.01em',
    color: '#FFFFFF',
    width: '75%',
    marginBottom: '2rem'
  },
  introSubtitle: {
    fontFamily: 'anonymous-pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '25px',
    lineHeight: '31px',
    letterSpacing: '0.19em',
    color: '#FFFFFF',
    textTransform: 'uppercase'
  },
  mobileIntroSubtitle: {
    fontFamily: 'anonymous-pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '0.19em',
    color: '#FFFFFF',
    textTransform: 'uppercase'
  }
});

const VxIntro = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className={classes.intro}>
      {!isMobile && (
        <ColorBars
          number={1}
          topPosition={'250px'}
          rightPosition={''}
          bottomPosition={''}
          leftPosition={'99.5%'}
        />
      )}
      {!isMobile && (
        <ColorBars
          number={7}
          topPosition={'500px'}
          rightPosition={'99.5%'}
          bottomPosition={''}
          leftPosition={''}
        />
      )}
      <div className={isMobile ? classes.mobileIntroText : classes.introText}>
        <div className={isMobile ? classes.mobileHeaderWrap : classes.headerWrap}>
          <div className={isMobile ? classes.mobileHeader : classes.header}>
            <div className={classes.plus}><Plus /></div>
            <div className={classes.title}>Introducing</div>
          </div>
        </div>
        <div className={isMobile ? classes.mobileIntroTitle : classes.introTitle}>
          The Vx360 Virtual Event Platform
        </div>
        <div className={isMobile ? classes.mobileIntroSubtitle : classes.introSubtitle}>
          Your Virtual Event.
        </div>
        <div className={isMobile ? classes.mobileIntroSubtitle : classes.introSubtitle}>
          Limitless Possibilities.
        </div>
      </div>
      {isMobile ? (
          <div className={classes.mobileBgVidContainer}>
          <video
            className={classes.mobileBackgroundVid}
            autoPlay={true}
            playsInline={true}
            disablePictureInPicture={true}
            muted={true}
            controls={false}
            loop={true}
          >
            <source src='https://s3.amazonaws.com/mvrk2.0/Vx360HeaderMobile.webm' type='video/webm' />
            <source src='https://s3.amazonaws.com/mvrk2.0/Vx360HeaderMobile.mp4' type='video/mp4' />
          </video>
        </div>
      ) : (
        <div className={classes.bgVidContainer}>
          <video
            className={classes.backgroundVid}
            autoPlay={true}
            playsInline={true}
            disablePictureInPicture={true}
            muted={true}
            controls={false}
            loop={true}
          >
            <source src='https://s3.amazonaws.com/mvrk2.0/Vx360HeaderDesktop.webm' type='video/webm' />
            <source src='https://s3.amazonaws.com/mvrk2.0/Vx360HeaderDesktop.mp4' type='video/mp4' />
          </video>
        </div>
      )}
    </div>
  );
};

export default VxIntro;
