import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#38F4F1',
    },
    error: {
      main: '#FC003C',
    },
    background: {
      main: '#000000',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;