import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from '../utils/carousel';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ColorBars from './ColorBars';
import Dialog from '@material-ui/core/Dialog';
import ExperienceModal from './ExperienceModal';
import SwiperSlide from './SwiperSlide';
import MvrkHeader from './MvrkHeader';
import { projects } from '../utils';

const useStyles = makeStyles({
  modalStyle: {
    width: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    margin: 0,
    padding: 0
  },
  modalWrap: {
    backgroundColor: '#111111',
    color: '#FFFFFF',
    height: '100vh',
    width: '100vw'
  }
});

const Experience = ({ history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    setLoading(false);
  }, []);

  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const onChange = () => {
    setCurrentSlide(carouselRef.current.carouselStore.state.currentSlide)
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.carouselStore.subscribe(onChange);
      return () => carouselRef.current.carouselStore.unsubscribe(onChange);
    }
  }, [loading]);

  useEffect(() => {
    setCurrentIndex(currentSlide);
  }, [currentSlide])

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSelect = () => {
    setShowModal(true);
  };

  const handleNavigate = dir => {
    if (dir === 'next') {
      if (currentIndex === projects.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    } else {
      if (currentIndex === 0) {
        setCurrentIndex(projects.length - 1);
      } else {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  return (
    loading ? null : (
      <section className='experience'>
        <div className='part one'>
          <div className='content'>
            <div className='header-wrap'>
              <MvrkHeader
                number='03'
                title='Experience'
              />
            </div>
            <div className='subtitle'>
              The Highlight Reel
            </div>
          </div>
        </div>
        <div className='part two'>
          <div className='image-caption'>
            <div className='caption-text'>{projects[currentIndex].blurb}</div>
            <div className='number'>
              {`0${currentIndex + 1}`}
              <span> / 07 -</span>
            </div>
          </div>
          <div
            className='project-title'
            onClick={() => setShowModal(true)}
          >
            <span className='brand'>{projects[currentIndex].brand}</span>
            <br />
            <span className='title-line'>{projects[currentIndex].title}</span>
            <br />
            {projects[currentIndex].title2 && (
              <span className='title-line'>{projects[currentIndex].title2}</span>
            )}
          </div>
          {!isMobile && (
            <ColorBars
              number={1}
              topPosition={'3075px'}
              rightPosition={''}
              bottomPosition={''}
              leftPosition={'99.5%'}
            />
          )}
          {isMobile ? (
            <React.Fragment>
              <CarouselProvider
                naturalSlideWidth={375}
                naturalSlideHeight={500}
                totalSlides={projects.length}
                infinite={true}
                ref={ref => carouselRef.current = ref}
              >
                <Slider>
                  {projects.map((project, index) => (
                    <Slide
                      key={`${project.id}${index}`}
                      index={index}
                      onClick={() => handleSelect()}
                    >
                      <SwiperSlide
                        project={project}
                        index={index}
                      />
                  </Slide>
                  ))}
                </Slider>
              </CarouselProvider>
              <div className='navigation'>
                <ChevronLeftIcon />
                <div style={{ marginTop: '1px' }}>Swipe to see more</div>
                <ChevronRightIcon />
              </div>
            </React.Fragment>
          ) : (
            <Carousel
              enable3d={true}
              showItemsCount={3}
              enableDragScroll={false}
              startIndex={6}
              handleSelect={val => handleSelect(val)}
              shareDirection={dir => handleNavigate(dir)}
            >
              {projects.map((project, index) => (
                <SwiperSlide
                  key={`${project.id}${index}`}
                  project={project}
                  index={index}
                />
              ))}
            </Carousel>
            )}
          <Dialog
            fullWidth={true}
            maxWidth='xl'
            open={showModal}
            onClose={handleClose}
            aria-labelledby='experience'
            classes={{ paperScrollPaper: classes.modalStyle, scrollPaper: classes.modalStyle, paper: classes.modalStyle, root: classes.modalStyle, container: classes.modalStyle }}
          >
            <div className={classes.modalWrap}>
              <ExperienceModal
                project={projects[currentIndex]}
                close={() => setShowModal(false)}
              />
            </div>
          </Dialog>
        </div>
      </section>
    )
  );
};

export default Experience;
