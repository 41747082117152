import React from 'react';
import Bars1 from '../assets/color-bars/bar1.png';
import Bars2 from '../assets/color-bars/bar2.png';
import Bars3 from '../assets/color-bars/bar3.png';
import Bars4 from '../assets/color-bars/bar4.png';
import Bars5 from '../assets/color-bars/bar5.png';
import Bars6 from '../assets/color-bars/bar6.png';
import Bars7 from '../assets/color-bars/bar7.png';
import Bars8 from '../assets/color-bars/bar8.png';

const ColorBars = ({
  number,
  leftPosition,
  rightPosition,
  topPosition,
  bottomPosition,
  width,
  fullSize = false,
}) => {
  const renderBars = () => {
    switch (number) {
      case 1:
        return (
          <img
            src={Bars1}
            height='50%'
            alt='color-bars'
          />
        );
      case 2:
        return (
          <img
            src={Bars2}
            height='50%'
            alt='color-bars'
          />
        );
      case 3:
        return (
          <img
            src={Bars3}
            height='50%'
            alt='color-bars'
          />
        );
      case 4:
        return (
          <img
            src={Bars4}
            height='50%'
            alt='color-bars'
          />
        );
      case 5:
        return (
          <img
            src={Bars5}
            height='50%'
            alt='color-bars'
            width='25%' />
          );
      case 6:
        return (
          <img
            src={Bars6}
            height='50%'
            alt='color-bars'
          />
        );
      case 7:
        return (
          <img
            src={Bars7}
            height='25%'
            width='50%'
            alt='color-bars'
          />
        );
      case 8:
        return (
          <img
            src={Bars8}
            height='25%'
            alt='color-bars'
            width='55%'
          />
        );
      default:
        return (
          <img
            src={Bars1}
            height='50%'
            alt='color-bars'
          />
        );
    }
  };

  return (
    <span
      className='color-bar'
      style={{
        position: 'absolute',
        zIndex: 1100,
        left: leftPosition,
        right: rightPosition,
        top: topPosition,
        bottom: bottomPosition,
        height: '50%'
      }}
    >
      {renderBars()}
    </span>
  );
};

export default ColorBars;
