import React from 'react';
import MvrkHeader from './MvrkHeader';
import Differently from '../assets/differentlyOval.png';
import Possibilities from '../assets/possibilitiesStrike.png';

const PageIntro = ({ number, title, subtitle, mainText, image }) => {
  const getImgSrc = () => {
    if (image === 'differently') {
      return Differently;
    } else {
      return Possibilities;
    }
  };

  return (
    <div className='page-intro'>
      <div className='header-wrap'>
        <MvrkHeader
          number={number}
          title={title}
        />
      </div>
      <div className='columns'>
        <div className='left'>
          <div className={`subtitle ${image}`}>
            {subtitle}
            <span className={`text-image ${image}`}>
              <img src={getImgSrc()} className={`img ${image}`} alt={image} />
            </span>
          </div>
        </div>
        <div className='right'>
          <p>{mainText.part1}</p>
          {mainText.part2 && (
            <p>{mainText.part2}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageIntro;
